import React from 'react'
import * as Components from '.'
import { IHeaderProps } from './type'


const Header: React.FC<IHeaderProps> = ({
  type,
  titles,
  sortHandler 
}) => {

  return (
    <header className={`table-header table--grid table--${type}`}>
      {titles.map((title, index) => (
        <Components.Cell
          key={index}
          type={title.type}
          slug={title.slug}
          sort={title.sort}
          sortHandler={sortHandler}
        />
      ))}
    </header>
  )
}

export default Header