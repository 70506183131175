import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Components from "./components";
import * as Buttons from "../../components/Buttons";

import { useHttp } from "../../hooks/http.hook";
import { config, Translater } from "../../config";
import { useParams, useHistory } from "react-router-dom";
import {
  TMutliLangTitles,
  TPossibleMultiLangTitle,
} from "../../types/multiLang";
import { TDetailValidationSchema, TFormTypes } from "./types";
import { useValidation } from "../../hooks";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { IOneCategory } from "../../types";

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { multiLang } = useContext(Contexts.ConfigContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { id } = useParams() as any;
  const history = useHistory();
  const { loading, request } = useHttp();

  const [primary, setPrimary] = useState<IOneCategory | null>(null);
  const [isValid, toggleValid] = useState({
    titleRU: false,
    titleUA: false,
    photo: true,
    position: true,
    restaurants: true,
  });
  const [form, setForm] = useState<TFormTypes>({
    titleRU: "",
    titleUA: "",
    hidden: false,
    promotionalOffer: false,
    parent_id: "",
    restaurant: [],
    photo: null,
    position: 0,
    products: [],
  });
  const [multiLangValues, setMultiLangValues] = useState<TMutliLangTitles>({
    "title[EN]": "",
    "title[RU]": "",
    "title[UA]": "",
  });

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);

  const schema = useMemo<TDetailValidationSchema>(
    () => ({
      titleRU: {
        condition: !!form?.titleRU,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.title[language.slug]} RU`,
      },
      titleUA: {
        condition: !!form?.titleUA,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.title[language.slug]} UA`,
      },
      photo: {
        condition: !!form?.photo || !!primary?.photo,
        error: Translater.ErrorPhoto[language.slug],
      },
      position: {
        condition: !!form?.position && !!parseInt(form.position + ""),
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.position[language.slug]}`,
      },
      restaurants: {
        condition:
          form?.restaurant?.length! > 1 || form?.restaurant?.length === 1,
        error: `${Translater.ErrorField[language.slug]}: ${
          Translater.ErrorRestaurants[language.slug]
        }`,
      },
    }),
    [form, Translater, language]
  );
  const { errors, validation } = useValidation(schema);

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.type === "file")
        //@ts-ignore
        setForm({ ...form, photo: e.target.files[0] });
      else if (e.target.name.split("[")[1]) {
        setMultiLangValues({
          ...multiLangValues,
          [e.target.name]: e.target.value,
        });
      } else setForm({ ...form, [e.target.name]: e.target.value });
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value });
    },
    setParentID: (parent_id: string) => {
      setForm({ ...form, parent_id });
    },
    setRestaurant: (restaurant: string[]) => {
      setForm({ ...form, restaurant });
    },
    saveHandler: async () => {
      try {
        await validation();
        Callbacks.Save();
      } catch (e) {
        toogleIsAlertOpen(true);
      }
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug]);
      if (answer) Callbacks.Delete();
    },
  };

  const Callbacks = {
    SaveProducts: async () => {
      form.products?.map(async (product) => {
        try {
          const data = new FormData();
          data.append("name", JSON.stringify(product.name));

          data.append("description[]", JSON.stringify(product.description));
          data.append("isVariated", product.isVariated ? "true" : "false");
          data.append("novelty", product.novelty ? "true" : "false");
          product.photo?.map((item: string) => data.append("photo[]", item));
          product.order && data.append("order", product.order.toString());

          await request(
            `${config.API}/products/${product?._id}`,
            "POST",
            data,
            {
              Authorization: `Bearer ${token as string}`,
            }
          );
        } catch (e) {}
      });
    },
    Fetch: async () => {
      try {
        const response: IOneCategory = await request(
          `${config.API}/categories/${id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        if (response) {
          setPrimary(response);
        }
      } catch (e) {}
    },
    FetchCategory: async () => {
      try {
        const response: IOneCategory = await request(
          `${config.API}/categories/${primary?.parent_id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        if (primary) setPrimary({ ...primary, parent_id: response._id });
      } catch (e) {
        console.log(e);
      }
    },
    Save: async () => {
      try {
        const data = new FormData();

        // if (multiLang) {
        //   for (let key in multiLangValues) {
        //     data.append(key, multiLangValues[key as TPossibleMultiLangTitle]);
        //   }
        // } else
        data.append(
          "title",
          JSON.stringify([
            { lang: "ru", string: form.titleRU },
            { lang: "ua", string: form.titleUA },
          ])
        );
        data.append("position", form.position + "");

        form.photo && data.append("photo", form.photo);
        data.append("hidden", form.hidden + "");
        data.append("promotionalOffer", form.promotionalOffer + "");
        if (form.restaurant)
          for (let rest of form.restaurant) {
            data.append("restaurant[]", rest);
          }
        data.append("parent_id", form.parent_id || "");
        const response = await request(
          `${config.API}/categories/${primary?._id}`,
          "POST",
          data,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        response && (await Callbacks.SaveProducts());
        if (response) history.push("/categories");
      } catch (e) {}
    },
    Delete: async () => {
      try {
        await request(`${config.API}/categories/${id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        });

        history.push("/categories");
      } catch (e) {}
    },
  };

  useEffect(() => {
    Callbacks.Fetch();
  }, [id]);

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      titleRU: primary?.title[0].string,
      titleUA: primary?.title[1].string,
      hidden: primary?.hidden || false,
      promotionalOffer: primary?.promotionalOffer,
      parent_id: primary?.parent_id || "",
      restaurant: [primary?.restaurant[0]._id || ""],
      position: primary?.position || 0,
    }));
  }, [primary]);

  useEffect(() => {
    if (primary?.parent_id) Callbacks.FetchCategory();
  }, [primary?.parent_id]);

  useEffect(() => {
    if (multiLang) {
      let isRight = true;

      for (let key in multiLangValues) {
        if (
          multiLangValues[key as TPossibleMultiLangTitle].length >= 2 &&
          isRight
        ) {
          toggleValid({ ...isValid, titleUA: true, titleRU: true });
          isRight = true;
        } else {
          isRight = false;
          toggleValid({ ...isValid, titleUA: false, titleRU: false });
        }
      }
    } else {
      if (form?.titleRU || form.titleUA)
        toggleValid({ ...isValid, titleUA: true, titleRU: true });
      else toggleValid({ ...isValid, titleUA: false, titleRU: false });
    }
  }, [form.titleRU, multiLangValues]);

  if (loading || !primary) return <Page.Preloader />;
  return (
    <Page.Wrapper title={Translater.CategoriesCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.CategoriesDetailPage.title[language.slug]}
        backButtonLink="/categories"
      />
      <Components.ConfigBlock
        data={primary}
        form={form}
        isValid={isValid}
        multiLangValues={multiLangValues}
        setForm={setForm}
        setParentID={Events.setParentID}
        inputHandler={Events.inputHandler}
        isDetailPage
        selectHandler={Events.selectHandler}
        setRestaurant={Events.setRestaurant}
      />

      <Components.DetailBlock data={primary} form={form} setForm={setForm} />
      <Buttons.Container
        disabled={loading}
        position={"relative"}
        saveHandler={access.categories?.change ? Events.saveHandler : false}
        deleteHandler={access.categories?.delete ? Events.deleteHandler : false}
      />
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  );
};

export default DetailPage;
