import React, { Fragment, useContext, useEffect, useState } from "react"

import * as Contexts from "../../../../contexts"
import * as Form from "../../../../components/Form"

import { Translater } from "../../../../config"

import { IConfigBlockProps } from "./types"

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  form,
  client,
  inputHandler,
}) => {
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const [isValid, toggleValid] = useState({
    name: false,
  })

  function formatDate(date: Date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }
  function isValidName(name = ""): boolean {
    if (name.length >= 2) {
      return true
    } else if (name.length <= 2) {
      return false
    } else {
      return false
    }
  }

  console.log("form", form.name)
  return (
    <Fragment>
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.title[language.slug]}
      >
        <Form.Input
          name="name"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isValidName(form.name)}
          value={form.name}
          inputHandler={inputHandler}
        />
      </Form.LabelField>
      {/* 
      {access.users?.rule === 'change' ? (
        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.dateOfBirth[language.slug]}
        >
          <Form.Input
            type='date'
            name='dateOfBirth'
            value={formatDate(form.dateOfBirth as any)}
            inputHandler={inputHandler}
          />
        </Form.LabelField>
      ) : null} */}

      <Form.LabelField
        fontSize="small"
        text={client.bonuses || "0"}
        label={Translater.TableTitles.bonuses[language.slug]}
      />
    </Fragment>
  )
}

export default ConfigBlock
