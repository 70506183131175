import { IOption } from '../../types/items'

export const SHiddenOptions: IOption[] = [
  { value: 'false', slug: 'no' },
  { value: 'true', slug: 'yes' },
]

export const SPromotionalOfferOptions: IOption[] = [
  { value: 'true', slug: 'yes' },
  { value: 'false', slug: 'no' },
]
