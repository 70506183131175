import React, { Fragment, useContext } from "react"

import * as Contexts from "../../../contexts"
import * as Form from "../../../components/Form"
import * as Rules from "./Rules"

import { Translater } from "../../../config"

import { IConfigBlockProps } from "./types"

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  form,
  setForm,
  inputHandler,
}) => {
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  return (
    <Fragment>
      {console.log("FORM", form)}
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.title[language.slug]}
      >
        <Form.Input
          name="title"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          value={form.title}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Rules.Root form={form} setForm={setForm} />
    </Fragment>
  )
}

export default ConfigBlock
