import React, { Fragment, useEffect, useContext } from "react";

import * as Contexts from "../../../contexts";
import * as Form from "../../../components/Form";

import { config, Translater } from "../../../config";
import { useHttp } from "../../../hooks";

import { IRole, ICity, IRestaurant } from "../../../types/items";
import { IConfigBlockProps } from "./types";

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  data,
  form,
  isClicked,
  photo,
  getOptions,
  setForm,
  inputHandler,
}) => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { request } = useHttp();

  const Events = {
    loadOptions: async (inputValue: string) => {
      try {
        const response: ICity[] = await request(
          `${config.API}/cities/?name=${inputValue}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        const formatted: ICity[] = [];
        if (response.length) {
          for (const item of response) {
            const option = {
              value: item._id as string,
              label: item.name as string,
            };
            formatted.push(option as any);
          }
        }
        return formatted;
      } catch (e) {}
    },
    colorPickerHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm && setForm({ ...form, headerColor: e.target.value });
    },
  };
  const RolesEvents = {
    loadRolesOptions: async () => {
      try {
        const response: IRole[] = await request(
          `${config.API}/roles?title=`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        const formatted: IRole[] = [];
        getOptions && getOptions!(response);
        return formatted;
      } catch (e) {}
    },
    optionRoleHandler: (option: IRole) => {
      if (option && option._id) {
        setForm && setForm({ ...form, role: option.title });
      }
    },
  };

  useEffect(() => {
    RolesEvents.loadRolesOptions();
  }, []);

  return (
    <Fragment>
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.title[language.slug]}
      >
        <Form.Input
          name="title"
          value={form.title}
          isValid={isClicked ? form.title.length >= 3 : true}
          placeholder={Translater.Placeholders.minThree[language.slug]}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.Placeholders.colorPicker[language.slug]}
      >
        <Form.DefaultColorPicker
          onHandler={Events.colorPickerHandler}
          defaultValue={data?.headerColor}
        />
      </Form.LabelField>

      {photo ? (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.photo[language.slug]}
        >
          {photo ? (
            <img
              alt="Restaurant"
              src={URL.createObjectURL(photo)}
              style={{ width: 350, height: 250, objectFit: "contain" }}
            />
          ) : null}
        </Form.LabelField>
      ) : (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.photo[language.slug]}
        >
          <img
            alt="Restaurant"
            src={`${config.imageProxy}${(data as IRestaurant)?.photo}`}
            style={{ width: 350, height: 250, objectFit: "contain" }}
          />
        </Form.LabelField>
      )}
      {access.restaurants?.change ? (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.photo[language.slug]}
        >
          <Form.Input
            type="file"
            name="photo"
            isValid={isClicked ? photo !== null : true}
            inputHandler={inputHandler}
          />
        </Form.LabelField>
      ) : null}
    </Fragment>
  );
};

export default ConfigBlock;
