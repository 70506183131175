import React, { useState, useEffect, useContext, ChangeEvent } from "react"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"

import { useHttp } from "../../hooks"

import * as Form from "../../components/Form"
import { config, Translater } from "../../config"
import * as Components from "./components"
import { IPagination, IRestaurant } from "../../types/items"

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, request, totalCount } = useHttp()

  const [rests, setRests] = useState<IRestaurant[]>([])
  const [rest, setRest] = useState<IRestaurant | null>(null)
  const [primary, setPrimary] = useState<any>([])
  const [pages, setPages] = useState<number>(0)
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: totalCount,
  })

  const Events = {
    reloadHandler: () => {
      Callbacks.Fetch()
    },

    paginateHandler: (page: number) => {
      setPagination({ ...pagination, page })
    },

    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setPagination({ ...pagination, page: 1, perPage: +e.target.value })
    },
    pressHandler: (e: React.KeyboardEvent) => {
      if (e.key === "Enter") Callbacks.Fetch()
    },
    selectRestHandler: (e: any) => {
      const value = e.target.value
      for (let rest of rests) {
        if (rest.title == value) {
          setRest(rest)
        }
      }
    },
  }

  const Callbacks = {
    GetRests: async () => {
      const Authorization = `Bearer ${token as string}`
      const rests: IRestaurant[] = await request(
        `${config.API}/restaurants`,
        "GET",
        null,
        {
          Authorization,
        }
      )
      if (rests.length) {
        setRest(rests[0])
        setRests(rests)
      }
    },
    Fetch: async () => {
      try {
        const response: any = await request(
          `${config.serverURL}api/booking/byRestaurant/${rest?._id}?page=${pagination.page}&perPage=${pagination.perPage}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        )

        const restaurants: IRestaurant[] = await request(
          `${config.API}/restaurants`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) {
          const cnv: any[] = []
          for (let book of response) {
            restaurants.forEach((i: IRestaurant) => {
              if (i._id == book.restaurant) {
                cnv.push({ ...book, restaurant: i.title })
              }
            })
          }
          setPrimary(cnv)
        }
      } catch (e) {}
    },
  }

  useEffect(() => {
    Callbacks.GetRests()
  }, [])

  useEffect(() => {
    Callbacks.Fetch()
  }, [pagination.perPage, pagination.page, rest?._id])

  useEffect(() => {
    setPagination({ ...pagination, totalCount })
  }, [totalCount])

  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper title={Translater.BookingPage.title[language.slug]}>
      <Page.Header
        title={Translater.BookingPage.title[language.slug]}
        linkTitle={Translater.Buttons.create[language.slug]}
        backButtonLink="/booking"
      />

      <Form.DefaultSelect
        options={rests}
        field="title"
        selectHandler={Events.selectRestHandler}
        name="optionsSelect"
        value={rest?.title || ""}
      />

      <Components.Table items={primary} reloadHandler={Events.reloadHandler} />

      <Page.Footer>
        <Page.Pagination
          pagination={pagination}
          setPagination={setPagination}
          paginateHandler={Events.paginateHandler}
          linkTo="/booking"
        />
      </Page.Footer>
    </Page.Wrapper>
  )
}

export default IndexPage
