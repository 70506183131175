import React, { useState, useEffect } from "react"

import { IPaginationProps } from "./types"
import { Link } from "react-router-dom"
import { IPagination } from "../../types/items"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import { Swiper, SwiperSlide } from "swiper/react"

const Pagination: React.FC<IPaginationProps> = ({
  linkTo,
  pagination,
  paginateHandler,
  slider = false,
  setPagination = (val: IPagination) => undefined,
}) => {
  const [links, setLinks] = useState<any[]>([])

  useEffect(() => {
    const count = Math.ceil(pagination.totalCount / pagination.perPage)
    let countItems = 10
    let initialLinks = []

    for (let i = 1; i <= count; i++) {
      initialLinks.push(i)
    }

    if (slider) {
      if (initialLinks.length > countItems) {
        const pages = Math.ceil(count / 10)
        const pagesArr: any[] = []

        for (let i = 0; i < pages; i++) {
          pagesArr.push([])
        }

        for (let i = 0; i < pages; i++) {
          const arr: any[] = []
          const allCount = countItems * i

          for (let p = allCount; p < allCount + countItems; p++) {
            if (initialLinks[p]) {
              arr.push(initialLinks[p])
            }
          }
          pagesArr[i] = arr
        }

        setLinks(pagesArr)
      } else {
        setLinks([initialLinks])
      }
    } else {
      setLinks(initialLinks)
    }
  }, [pagination.perPage, pagination.page, pagination.totalCount])

  useEffect(() => {
    const pags = localStorage.getItem("PAGINATION_STORAGE")
    if (pags) {
      setPagination({ ...pagination, perPage: JSON.parse(pags).perPage })
    }
  }, [])

  if (slider) {
    return (
      <Swiper speed={1000} spaceBetween={0} slidesPerView={0} navigation>
        {links.map((items) => {
          return (
            <SwiperSlide>
              {items.map((link: any) => (
                <Link
                  key={link}
                  className={`pagination-link ${
                    link === pagination.page && "active"
                  }`}
                  to={linkTo}
                  onClick={() => paginateHandler(link)}
                >
                  {link}
                </Link>
              ))}
            </SwiperSlide>
          )
        })}
      </Swiper>
    )
  }

  return (
    <div className={`pagination-wrapper--slider`}>
      <div className="wrap">
        {links.map((link) => (
          <Link
            key={link}
            className={`pagination-link ${
              link === pagination.page && "active"
            }`}
            to={linkTo}
            onClick={() => paginateHandler(link)}
          >
            {link}
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Pagination
