import React from 'react'
import * as Components from '.'
import { ITableProps } from './type'


const Table: React.FC<ITableProps> = ({
  type,
  titles,
  children,
  sortHandler
}) => {

  return (
    <div className='table'>
      <Components.Header
        type={type}
        titles={titles}
        sortHandler={sortHandler}
      />

      <Components.Body
        type={type}
      >
        {children}
      </Components.Body>
    </div>
  )
}

export default Table