import React, { Fragment, useContext } from "react"

import * as Contexts from "../../../../contexts"
import * as Table from "../../../../components/Table"

import { config, Translater } from "../../../../config"
import { ITrackProps } from "./types"
import { useHistory } from "react-router-dom"
import { useHttp } from "../../../../hooks/http.hook"
import moment from "moment"

const Track: React.FC<ITrackProps> = ({ data, nesting, reloadHandler }) => {
  const history = useHistory()
  const { access } = useContext(Contexts.UserContext)

  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()

  const Events = {
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
    editHandler: () => {
      history.push(`/booking/${data._id}`)
    },
  }

  const Callbacks = {
    Delete: async () => {
      try {
        await request(`${config.API}/booking/${data._id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        })

        reloadHandler()
      } catch (e) {
        console.log(e)
      }
    },
  }

  return (
    <Fragment>
      <Table.Track type="booking">
        <Table.Cell text={(nesting + 1).toString()} />

        <Table.Cell text={data.guests} />

        <Table.Cell text={data.phone} />

        <Table.Cell
          text={moment(data.date)
            .utcOffset("+0000")
            .format("DD.MM, YYYY HH:mm")}
          modalContent={
            <Table.ModalContent
              text={moment(data.date)
                .utcOffset("+0000")
                .format("DD.MM, YYYY HH:mm")}
            />
          }
        />

        <Table.Cell
          date={new Date(data.createdAt as string)}
          format="DD.MM.YYYY, HH:mm"
          modalContent={
            <Table.ModalContent
              text={moment(data.createdAt)
                .utcOffset("+0000")
                .format("DD.MM, YYYYTHH:mm")}
            />
          }
        />

        <Table.Cell text={data.restaurant} />

        {access.booking?.delete && (
          <Table.Cell
            deleteButton
            type="control"
            deleteHandler={Events.deleteHandler}
          />
        )}
      </Table.Track>
    </Fragment>
  )
}

export default Track
