export enum EVars {
  USER_DATA = 'USER_DATA'
}

export interface IAuthContext {
  token: string | null
  userID: string | null
  isReady: boolean
  isAuthenticated: boolean
  actions: IAuthContextActions
}

export interface IUserData {
  token: string
  userID: string
}

export interface IAuthContextActions {
  LOGIN: TLogin
  LOGOUT: TLogout
  GET_USER: TGET_USER
}

type TLogin = {
  (
    token: string, userID: string
  ): void
}
type TLogout = {
  (): void
}

type TGET_USER = {
  (): void
}
