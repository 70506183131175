import React, { useContext } from 'react'

import * as Contexts from '../../contexts'

import { Link } from 'react-router-dom'

import { ILinkProps } from './types'

const LinkCustom: React.FC<ILinkProps> = ({
  title,
  linkTo,
  refresh = false,
  hideModal = false,
}) => {
  return (
    <Link className='btn--link' to={linkTo}>
      {title}
    </Link>
  )
}

export default LinkCustom
