import React, { useState, useEffect, useContext, ChangeEvent } from "react"

import * as Components from "./components"
import * as Contexts from "../../contexts"
import * as Buttons from "../../components/Buttons"
import * as Page from "../../components/Page"
import * as Form from "../../components/Form"

import { useHistory, useParams } from "react-router-dom"
import { config, Translater } from "../../config"
import { useHttp } from "../../hooks/http.hook"

const DetailPage: React.FC = () => {
  const { id } = useParams() as any
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { loading, request } = useHttp()
  const [loader, toogleLoader] = useState<boolean>(false)

  const [primary, setPrimary] = useState<any | null>(null)
  const [isValid, toggleValid] = useState({
    phone: false,
    guests: false,
    date: false,
  })
  const [form, setForm] = useState<any>({
    guests: "",
    phone: "",
    date: "",
  })

  const Events = {
    inputHandler: (
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
      if (e.target.type === "file")
        //@ts-ignore
        setForm({ ...form, photo: e.target.files[0] })
      else setForm({ ...form, [e.target.name]: e.target.value })
    },
    setCity: (city: string) => {
      setForm({ ...form, city })
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
  }

  const Callbacks = {
    Fetch: async () => {
      toogleLoader(true)
      try {
        const response: any = await request(
          `${config.API}/booking/${id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )
        if (response) {
          setPrimary(response)
        }
      } catch (e) {
      } finally {
        toogleLoader(false)
      }
    },
    Delete: async () => {
      try {
        await request(`${config.API}/booking/${id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        })

        history.push("/booking")
      } catch (e) {}
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [id])

  useEffect(() => {
    if (primary)
      setForm({
        ...form,
        guests: primary.guests,
        phone: primary.phone,
        date: primary.date,
      })
  }, [primary])

  return (
    <Page.Wrapper title={Translater.BookingDetailPage.title[language.slug]}>
      <Page.WaitWrapper loader={loader} data={primary} withoutNoLength>
        <Page.Header
          backButtonTitle={Translater.BookingDetailPage.title[language.slug]}
        />

        <Buttons.Container
          disabled={loading}
          deleteHandler={Events.deleteHandler}
        />

        <Components.ConfigBlock
          isCreate
          form={form}
          isValid={isValid}
          inputHandler={Events.inputHandler}
        />

        <Form.DateBlock
          updated={primary.updated as string}
          created={primary.created as string}
        />
      </Page.WaitWrapper>
    </Page.Wrapper>
  )
}

export default DetailPage
