import React, { useState, useEffect, createContext } from "react";

import { config } from "../config";
import { useHttp } from "../hooks";

import {
  IConfigContext,
  IConfigContextActions,
} from "../types/contexts/config";

const ConfigContext = createContext<IConfigContext>({
  isReady: false,
  languages: [],
  multiLang: 0,
});

export default ConfigContext;

export const ConfigContextProvider: React.FC = ({ children }) => {
  const { request } = useHttp();

  const [isReady, toggleReady] = useState<boolean>(false);
  const [languages, setLanguages] = useState([]);
  const [multiLang, setMultiLang] = useState<number>(0);

  const actions: IConfigContextActions = {
    FETCH_CONFIG: async () => {
      try {
        toggleReady(false);
        // const response = await request(`${config.API}/modules.json`, "GET")

        // if (response) {
        //   setMultiLang(response.modules.multi_lang)
        //   setLanguages(response.options.languages)
        //   setModules(response.modules)
        // }
        // console.log("response", response)
        toggleReady(true);
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    actions.FETCH_CONFIG();
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        languages,
        multiLang,
        isReady,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
