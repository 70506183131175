import { ITableTitle } from "../../../../types/table"

export const SHomeTitles: ITableTitle[] = [
  { type: "header", slug: "" },
  { type: "header", slug: "photo" },
  { type: "header", slug: "title", sort: true },
  { type: "header", slug: "hidden", sort: true },
  { type: "header", slug: "createdAt", sort: true },
  { type: "header", slug: "updatedAt", sort: true },
  { type: "header", slug: "amountOfProducts" },
]
