import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Buttons from "../../components/Buttons";
import * as Components from "./components";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import { TCreateValidationSchema, TFormTypes } from "./types";
import { config, Translater } from "../../config";
import { useValidation } from "../../hooks";
import { useHistory } from "react-router-dom";
import { useHttp } from "../../hooks";
import {
  TMutliLangTitles,
  TPossibleMultiLangTitle,
} from "../../types/multiLang";

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { multiLang } = useContext(Contexts.ConfigContext);
  const { language } = useContext(Contexts.LanguageContext);
  const { loading, request } = useHttp();
  const history = useHistory();

  const [isClicked, setClicked] = useState(false);

  const [form, setForm] = useState<TFormTypes>({
    titleRU: "",
    titleUA: "",
    hidden: false,
    promotionalOffer: false,
    parent_id: "",
    restaurant: [],
    photo: null,
    position: 0,
    products: [],
  });

  const [isValid, toggleValid] = useState({
    title: false,
    photo: false,
  });

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);

  const schema = useMemo<TCreateValidationSchema>(
    () => ({
      titleRU: {
        condition: form?.titleRU?.length! >= 2,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.title[language.slug]}`,
      },
      titleUA: {
        condition: form?.titleUA?.length! >= 2,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.title[language.slug]}`,
      },
      photo: {
        condition: !!form?.photo,
        error: Translater.ErrorPhoto[language.slug],
      },
      position: {
        condition: !!form?.position && !!parseInt(form.position + ""),
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.position[language.slug]}`,
      },
      restaurants: {
        condition:
          form?.restaurant?.length! > 1 || form?.restaurant?.length === 1,
        error: `${Translater.ErrorField[language.slug]}: ${
          Translater.ErrorRestaurants[language.slug]
        }`,
      },
    }),
    [form, Translater, language]
  );

  const { errors, validationSchema, validation } = useValidation(schema);

  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    "title[EN]": "",
    "title[RU]": "",
    "title[UA]": "",
  });

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.type === "file" && e?.target?.files) {
        setForm({ ...form, photo: e?.target?.files[0] });
      } else if (e.target.name.split("[")[1]) {
        setMultiLangTitles({
          ...multiLangTitles,
          [e.target.name]: e.target.value,
        });
      } else setForm({ ...form, [e.target.name]: e.target.value });
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value });
    },
    setParentID: (parent_id: string) => {
      setForm({ ...form, parent_id });
    },
    setRestaurant: (restaurant: string[]) => {
      setForm({ ...form, restaurant });
    },

    createHandler: async () => {
      try {
        setClicked(true);
        await validation();

        Callbacks.Create();
      } catch (e) {
        toogleIsAlertOpen(true);
        console.log("createHandler => e", e);
      }
    },
  };

  const Callbacks = {
    Create: async () => {
      try {
        const data = new FormData();

        if (multiLang) {
          for (let key in multiLangTitles) {
            data.append(key, multiLangTitles[key as TPossibleMultiLangTitle]);
          }
        } else
          data.append(
            "title",
            JSON.stringify([
              { lang: "ru", string: form.titleRU },
              { lang: "ua", string: form.titleUA },
            ])
          );

        data.append("hidden", form.hidden + "");
        data.append("position", form.position + "");
        data.append("promotionalOffer", form.promotionalOffer + "");
        if (form.restaurant)
          for (let rest of form.restaurant) {
            // @ts-ignore
            data.append("restaurant[]", [rest]);
          }
        form.photo && data.append("photo", form.photo);

        if (form.parent_id) {
          data.append("parent_id", form.parent_id);
        }

        const response = await request(
          `${config.API}/categories`,
          "POST",
          data,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        if (response) {
          history.push("/categories");

          setForm({
            titleRU: "",
            titleUA: "",
            hidden: false,
            promotionalOffer: false,
            parent_id: "",
            restaurant: [],
            photo: null,
            position: 0,
            products: [],
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    if (multiLang) {
      let isRight = true;

      for (let key in multiLangTitles) {
        if (
          multiLangTitles[key as TPossibleMultiLangTitle].length >= 2 &&
          isRight
        ) {
          toggleValid({ ...isValid, title: true });
          isRight = true;
        } else {
          isRight = false;
          toggleValid({ ...isValid, title: false });
        }
      }
    }
  }, [form, multiLangTitles]);

  return (
    <Page.Wrapper title={Translater.CategoriesCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.CategoriesCreatePage.title[language.slug]}
        backButtonLink="/categories"
      />
      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />
      <Components.ConfigBlock
        form={form!}
        isValid={validationSchema}
        multiLangValues={multiLangTitles}
        setForm={setForm}
        setParentID={Events.setParentID}
        inputHandler={Events.inputHandler}
        selectHandler={Events.selectHandler}
        setRestaurant={Events.setRestaurant}
        isClicked={isClicked}
      />
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  );
};

export default CreatePage;
