import React from 'react'


const Footer: React.FC = ({children}) => {

  return (
    <footer className='page-footer'>
      {children}
    </footer>
  )
}

export default Footer