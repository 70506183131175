import React, { useContext } from "react";
import * as Contexts from "../../contexts";
import * as Page from "./";
import { Translater } from "../../config";
import { INoLengthProps } from "./types";

const NoLength: React.FC<INoLengthProps> = ({ no }) => {
    const { language } = useContext(Contexts.LanguageContext)
    return (
        <Page.Wrapper>
            {no ? no : Translater.Items.NoLength[language.slug]}
        </Page.Wrapper>
    );
};

export default NoLength;