import React, { Fragment, useContext } from 'react'

import * as Contexts from '../../contexts'
import * as Buttons from '../Buttons'
import * as Fonts from '../Fonts'

import {Translater} from '../../config'

const Network: React.FC = () => {

  const {language} = useContext(Contexts.LanguageContext)

  const reloadHandler = () => window.location.reload(true)

  return (
    <Fragment>
      <Fonts.Title 
        title={Translater.Erros.oops[language.slug]}
      />

      <Fonts.Title 
        title={Translater.Erros.network[language.slug]}
      />

      <Buttons.DefaultButton 
        title={Translater.Buttons.reload[language.slug]}
        buttonHandler={reloadHandler}
      />
    </Fragment>
  )
}

export default Network