import { IOption } from "./../../../types/items"

export const SOptions: IOption[] = [
  { value: "new", slug: "new" },
  { value: "delivering", slug: "delivering" },
  { value: "completed", slug: "completed" },
  { value: "canceled", slug: "canceled" },
  { value: "processing", slug: "processing" },
  { value: "pending payment", slug: "pending payment" },
]

export const sortByOptions: IOption[] = [
  { value: "createdAt", slug: "createdAt" },
  { value: "orderStatus", slug: "orderStatus" },
  { value: "restaurant", slug: "restaurant" },
]
