import React, {
  useState,
  useEffect,
  createContext
} from 'react'

import {
  IOnlineContext,
  TPossibleOnlineStatuses
} from '../types/contexts/online'


const OnlineContext = createContext<IOnlineContext>({
  isOnline: true,
  status: '(online)'
})

export default OnlineContext


export const OnlineContextProvider: React.FC = ({ children }) => {

  const [status, setStatus] = useState<TPossibleOnlineStatuses>('(online)')
  const [isOnline, toggleOnline] = useState<boolean>(true)

  window.addEventListener('online', () => toggleOnline(true))

  window.addEventListener('offline', () => toggleOnline(false))

  useEffect(() => {
    if (isOnline) setStatus('(online)')
    else setStatus('(offline)')
  }, [isOnline])


  return (
    <OnlineContext.Provider value={{
      status, isOnline
    }}>
      {children}
    </OnlineContext.Provider>
  )
}