import React, { useState, useContext, useEffect } from "react";
import { ICategory } from "../../types/items";

import { useHttp } from "../../hooks/http.hook";
import * as Contexts from "../../contexts";

import { config, Translater } from "../../config";
import "./stocks.scss";
import { IOneCategory } from "../../types";

const ListCategories: React.FC<{
  categoriesList: string;
  selectHandler: (e: any) => void;
  restaurant: string;
}> = ({ selectHandler, categoriesList, restaurant }) => {
  const { token } = useContext(Contexts.AuthContext);
  const { request } = useHttp();
  const [primary, setPrimary] = useState<IOneCategory[]>([]);
  const { language } = useContext(Contexts.LanguageContext);

  let res;
  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IOneCategory[] = await request(
          `${config.API}/categories/62908c80859c0b034f521870/byRestaurant?perPage=100`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        if (response) {
          setPrimary(response);
        }
        res = response;
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    Callbacks.Fetch();
  }, []);

  return (
    <select
      name="categoriesList"
      value={categoriesList}
      onChange={selectHandler}
      onClick={selectHandler}
      className="input-list"
    >
      <option value="" disabled>
        {Translater.Placeholders.chooseCategory[language.slug]}
      </option>
      {primary.map(({ _id, title }, i) => {
        return (
          <option key={i} value={_id}>
            {(title && title[language.slug === "ua" ? 1 : 0].string) || ""}
          </option>
        );
      })}
    </select>
  );
};

export default ListCategories;
