import React from 'react'

import { ITitleProps } from './types'


const Title: React.FC<ITitleProps> = ({
  title,
  fontSize = 'medium',
  fontWeight = 'bold'
}) => {

  return (
    <h2
      className={`title ${fontSize} ${fontWeight}`}
    >
      {title}
    </h2>
  )
}

export default Title