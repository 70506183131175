import React, { useContext } from "react";
import * as Contexts from "../../contexts";
import * as Buttons from ".";
import { Translater } from "../../config";
import { TContainerProps } from "./types";

const Container: React.FC<TContainerProps> = ({
  disabled,
  position = "absolute",
  saveHandler,
  logoutHandler,
  createHandler,
  deleteHandler,
  pushHandler,
  updateProductsHandler,
}) => {
  const { language } = useContext(Contexts.LanguageContext);
  const style = { width: "47%", justifyContent: "space-between" };

  return (
    <div
      className={`page-btns-container ${
        position === "absolute" ? "page-btns-container--absolute" : ""
      }`}
      style={pushHandler ? style : {}}
    >
      {createHandler ? (
        <Buttons.DefaultButton
          title={Translater.Buttons.create[language.slug]}
          backgroundColor="green"
          disabled={disabled}
          buttonHandler={createHandler as () => void}
        />
      ) : null}

      {updateProductsHandler ? (
        <Buttons.DefaultButton
          title={Translater.Buttons.updateProducts[language.slug]}
          backgroundColor="green"
          disabled={disabled}
          buttonHandler={updateProductsHandler as () => void}
        />
      ) : null}

      {logoutHandler ? (
        <Buttons.DefaultButton
          title={Translater.Buttons.logout[language.slug]}
          backgroundColor="red"
          buttonHandler={logoutHandler as () => void}
        />
      ) : null}

      {saveHandler ? (
        <Buttons.DefaultButton
          title={Translater.Buttons.save[language.slug]}
          backgroundColor="yellow"
          disabled={disabled}
          buttonHandler={saveHandler as () => void}
        />
      ) : null}

      {pushHandler ? (
        <Buttons.DefaultButton
          title={Translater.Buttons.push[language.slug]}
          backgroundColor="green"
          disabled={disabled}
          buttonHandler={pushHandler as () => void}
        />
      ) : null}

      {deleteHandler ? (
        <Buttons.DefaultButton
          title={Translater.Buttons.delete[language.slug]}
          backgroundColor="red"
          disabled={disabled}
          buttonHandler={deleteHandler as () => void}
        />
      ) : null}
    </div>
  );
};

export default Container;
