import React from "react";
import { IRadioButtonProps } from "./types";

const RadioButton: React.FC<IRadioButtonProps> = ({
    data,
    radioButtonHandler,
    desc,
}) => {
    return (
        <form onChange={radioButtonHandler} className="radiobuttons"> 
            <p>{desc}</p> 
            {data.map(({ id, value, slug }) => {
                return (
                    <>
                        <input  
                            type="radio" className={`radiobutton`}
                            id={`radiobutton-id-${id}`}
                            name={`radio-${data.length}`} value={slug}
                        /> 
                        <label htmlFor={`radiobutton-id-${id}`}> 
                            <span className="radiobutton--desc">{value}</span>
                        </label> 
                    </>
                )
        })}
        </form> 
    );
};

export default RadioButton;   