import React, { Fragment, useContext } from "react";

import * as Contexts from "../../../../contexts";
import * as Buttons from "../../../../components/Buttons";
import * as Form from "../../../../components/Form";
import { config } from "../../../../config";
import { Translater } from "../../../../config";
import { IDetailBlockProps } from "./types";
import { ProducstDropableList } from "./ProductsDropableList";

const DetailBlock: React.FC<IDetailBlockProps> = ({ data, setForm, form }) => {
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  return (
    <Fragment>
      {/* <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.photo[language.slug]}
      >
        <img
          alt="Category"
          src={config.imageProxy + data.photo}
          style={{ objectFit: "cover", width: 350, height: 250 }}
        />
      </Form.LabelField> */}

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.subCategoriesCount[language.slug]}
        text={data.children?.length + ""}
      />

      {data.children?.length ? (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.subCategories[language.slug]}
        >
          {data.children?.map((child, index) => (
            <Fragment key={child._id}>
              <Buttons.Link
                title={child.title[language.slug === "ru" ? 0 : 1].string}
                linkTo={`/categories/${child._id}`}
              />

              {index + 1 !== data.children?.length ? (
                <span className="comma">,</span>
              ) : null}
            </Fragment>
          ))}
        </Form.LabelField>
      ) : null}

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.productsCount[language.slug]}
        text={`${data.products?.length}`}
      />

      <Form.DateBlock
        updated={data.updatedAt as string}
        created={data.createdAt as string}
      />

      <ProducstDropableList
        products={data ? data.products : []}
        form={form}
        setForm={setForm}
      />
    </Fragment>
  );
};

export default DetailBlock;
