import React from 'react'

import * as Table from '../../../../components/Table'
import * as Components from '..'

import { ITableWrapperProps } from './types'

import { SHomeTitles } from './static'


const TableWrapper: React.FC<ITableWrapperProps> = ({
  data
}) => {

  return (
    <Table.Table
      type='home'
      titles={SHomeTitles}
    >
      <Components.Track
        data={data}
      />
    </Table.Table>
  )
}

export default TableWrapper