import React, { useContext, MouseEvent } from 'react'

import * as Contexts from '../../contexts'
import * as Components from './components'


const Modal: React.FC = () => {

  const { isActive, type, data, actions: modalActions } = useContext(Contexts.ModalContext)

  const Events = {
    closeHandler: () => {
      modalActions.HIDE()
    },
    contentHandler: (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
    }
  }

  return (
    <div
      className={`page-modal-wrapper ${isActive && 'active'}`}
      onClick={Events.closeHandler}
    >
      <div
        className='page-modal-content'
        onClick={Events.contentHandler}
      >
        {data && type === 'status' ? (
          <Components.StatusModal
            data={data}
            reloadHandler={modalActions.HIDE}
          />
        ) : null}
      </div>
    </div>
  )
}

export default Modal