import React from 'react'


const Wrapper: React.FC = ({ children }) => {

  return (
    <div className='errors-wrapper'>
      <div className='errors-container'>
        {children}
      </div>
    </div>
  )
}

export default Wrapper