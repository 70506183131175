import React, { useContext } from "react";

import * as Contexts from "../../../../contexts";
import * as Form from "../../../../components/Form";
import { config, Translater } from "../../../../config";
import { IProductCardProps } from "./types";

const ProductCard: React.FC<IProductCardProps> = ({ data }) => {
  const { language } = useContext(Contexts.LanguageContext);

  const additionSum = data?.additions?.reduce((accumulator:any, currentValue:any) => accumulator + currentValue.price, 0);

  return (
    <div className="block-container border">
      <h2 className="block-title">{data?.product?.name}</h2>

      <Form.LabelField
        label={Translater.TableTitles.photo[language.slug]}
        fontSize="small"
      >
        <img alt="Product" src={`${config.imageProxy}${data?.product?.photo}`} />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.title[language.slug]}
        text={data?.product?.name}
      />
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.description[language.slug]}
        text={data?.product?.description}
      />
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.options[language.slug]}
        text={data?.additions
          .map(
            (addition: any) =>
              `${addition?.additiion?.name}: x${
                addition?.count
              } пор. `
          )
          .join(", ")}
      />

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.amount[language.slug]}
        text={`${data.count}`}
      />
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.price[language.slug]}
        text={`${data.price} грн`}
      />
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.sum[language.slug]}
        text={`${data.price * additionSum} грн`}
      />
    </div>
  );
};

export default ProductCard;
