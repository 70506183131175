import React from 'react'
import { IBodyProps } from './type'

const Body: React.FC<IBodyProps> = ({
  type,
  children
}) => {

  return (
    <div className={`table-body`}>
      {children}
    </div>
  )
}

export default Body