import React, { createContext, useContext, useState, useEffect } from "react"

import * as Contexts from "."

import { useHttp } from "../hooks/http.hook"
import { config } from "../config"

import { IUserContext, IUserContextActions } from "../types/contexts/user"
import { IUser } from "../types/items"

const UserContext = createContext<IUserContext>({
  user: {},
  access: {} as any,
  isReady: false,
})

export default UserContext

export const UserContextProvider: React.FC = ({ children }) => {
  const { token } = useContext(Contexts.AuthContext)

  const { request } = useHttp()

  const [isReady, toggleReady] = useState<boolean>(false)
  const [user, setUser] = useState<IUser | {}>({})
  const [access, setAccess] = useState<any>({})

  const actions: IUserContextActions = {
    FETCH_USER: async () => {
      try {
        toggleReady(false)

        const response = await request(`${config.API}/login`, "GET", null, {
          Authorization: `Bearer ${token as string}`,
        })

        if (response) {
          setUser(response)
          setAccess(response.role.rules)
        } else {
          setUser({})
          setAccess({})
        }

        toggleReady(true)
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    if (token) actions.FETCH_USER()
    else toggleReady(true)
  }, [token])

  return (
    <UserContext.Provider
      value={{
        user,
        isReady,
        access,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
