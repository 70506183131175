import React from "react"
// import * as Buttons from '../Buttons/DefaultButton'
// import Button from 'react-bootstrap/Button';
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import { IBlockProps } from "./types"
const ExportCSV: React.FC<IBlockProps> = ({ csvData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  const fileExtension = ".xlsx"
  const exportToCSV = (csvData: any, fileName: any) => {
    const ws = XLSX.utils.json_to_sheet(csvData)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }
  return (
    <button
      className="btn--excel-btn"
      onClick={(e) => exportToCSV(csvData, fileName)}
    >
      Export
    </button>
  )
}

export default ExportCSV
