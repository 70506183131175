import React, { useState, useEffect, useContext } from "react"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"
import * as Components from "./components"

import { useHttp } from "../../hooks"
import { config, Translater } from "../../config"
import { IStaff, IStatistics } from "../../types/items"
import { Title } from "../../components/Fonts"
import Container from "../../components/Page/Container"

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<IStaff | null>(null)
  const [statistics, setStatistics] = useState<IStatistics>()

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IStaff = await request(
          `${config.API}/login`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) setPrimary(response)
      } catch (e) {
        console.log(e)
      }
    },
    GetStatistics: async () => {
      try {
        const response: IStatistics = await request(
          `${config.API}/statistics`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )
        setStatistics(response)
        console.log(response)
      } catch (e) {}
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])
  useEffect(() => {
    Callbacks.GetStatistics()
  }, [])

  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper title={Translater.HomePage.title[language.slug]}>
      <Page.Header title={Translater.HomePage.title[language.slug]} />

      <Components.Table data={primary} />
      <Container>
        <div className="container-left-block">
          <Title title={Translater.DeliveringOrders[language.slug]} />
        </div>
        <Title title={statistics?.deliveringOrders?.toString() || ""} />
      </Container>
      <Container>
        <div className="container-left-block">
          <Title title={Translater.DoneOrders[language.slug]} />
        </div>
        <Title title={statistics?.doneOrders?.toString() || ""} />
      </Container>
      <Container>
        <div className="container-left-block">
          <Title title={Translater.NewByCashOrders[language.slug]} />
        </div>
        <Title title={statistics?.newByCashOrders?.toString() || ""} />
      </Container>
      <Container>
        <div className="container-left-block">
          <Title title={Translater.NewUsers[language.slug]} />
        </div>
        <Title title={statistics?.newUsers?.toString() || ""} />
      </Container>
      <Container>
        <div className="container-left-block">
          <Title title={Translater.PaymentErrorOrders[language.slug]} />
        </div>
        <Title title={statistics?.paymenterrorOrders?.toString() || ""} />
      </Container>
      <Container>
        <div className="container-left-block">
          <Title title={Translater.PaymentPaidOrders[language.slug]} />
        </div>
        <Title title={statistics?.paymentpaidOrders?.toString() || ""} />
      </Container>
      <Container>
        <div className="container-left-block">
          <Title title={Translater.RejectedOrders[language.slug]} />
        </div>
        <Title title={statistics?.rejectedOrders?.toString() || ""} />
      </Container>
      <Container>
        <div className="container-left-block">
          <Title title={Translater.TotalCompleted[language.slug]} />
        </div>
        <Title title={statistics?.totalOrders?.toString() || ""} />
      </Container>
      <Container>
        <div className="container-left-block">
          <Title title={Translater.DeliveringOrders[language.slug]} />
        </div>
        <Title title={statistics?.deliveringOrders?.toString() || ""} />
      </Container>

      <Container>
        <div className="container-left-block">
          <Title title={Translater.TotalUsers[language.slug]} />
        </div>
        <Title title={statistics?.totalUsers?.toString() || ""} />
      </Container>

      <Container>
        <div className="container-left-block">
          <Title title={Translater.WorkingOrders[language.slug]} />
        </div>
        <Title title={statistics?.workingOrders?.toString() || ""} />
      </Container>

      <Container>
        <div className="container-left-block">
          <Title title={Translater.TotalCompletedByReferals[language.slug]} />
        </div>
        <Title title={statistics?.usedReferals?.toString() || ""} />
      </Container>
    </Page.Wrapper>
  )
}

export default IndexPage
