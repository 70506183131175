import React, { useState, useEffect, useContext, ChangeEvent } from "react";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Components from "./components";

import { useHttp } from "../../hooks";

import { config, Translater } from "../../config";

import {
  IFilter,
  IPagination,
  IRestaurant,
  TCategorySortBy,
  TSortBy,
} from "../../types/items";
import ListRestaurants from "../Stocks/ListRestaurants";
import { IOneCategory } from "../../types";

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { loading, request, totalCount } = useHttp();

  const [primary, setPrimary] = useState<IOneCategory[]>([]);
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 1000,
    totalCount: 0,
  });
  const [restId, setRestId] = useState({
    restaurantsList: "",
  });
  const [searchValue, setSearchValue] = useState<string>("");
  const [defaultValue, setDefaultValue] = useState<IOneCategory[]>([]);
  const [searchRest, setSearchRest] = useState<string>("");
  const [filter, setFilter] = useState<IFilter>({
    sortBy: "position",
    sortOrder: "asc",
  });

  const Events = {
    reloadHandler: async () => {
      await Callbacks.Fetch();
    },

    searchHandler: (e: any) => {
      if (e.target.value.length === 0) {
        setPrimary(defaultValue);
      }
      setSearchValue(e.target.value);
    },

    pressHandler: (e: any) => {
      if (e.key === "Enter") Callbacks.Search();
    },
    sortHandler: (name: TSortBy | TCategorySortBy) => {
      let sortOrder: "asc" | "desc";
      if (filter.sortOrder === "asc") {
        sortOrder = "desc";
      } else {
        sortOrder = "asc";
      }
      setFilter({
        sortBy: name,
        sortOrder,
      });
    },

    paginateHandler: (page: number) => {
      setPagination({
        page: 1,
        perPage: 1000,
        totalCount: 0,
      });
    },
    selectHandlerRestId: (e: ChangeEvent<HTMLSelectElement>) => {
      setRestId({ ...restId, [e.target.name]: e.target.value });
    },
    selectHandler: (e: any) => {
      const perPage = +e.target.value;
      setPagination({
        page: 1,
        perPage: 1000,
        totalCount: 0,
      });
    },
    optionHandler: (option: IRestaurant) => {
      setSearchRest("");
      const _id = option._id;
      console.log(_id, "_id");
      setSearchRest(_id);
    },
    clearFilterHandler: () => {
      setSearchRest("");
    },
    saveButtonCliclHandler: () => {
      Callbacks.UpdatePositions();
    },
  };

  const Callbacks = {
    Fetch: async () => {
      try {
        if (restId.restaurantsList.length === 0) {
          const response: IOneCategory[] = await request(
            `${config.API}/categories/?page=${pagination.page}&perPage=1000&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${token as string}`,
            },
            true
          );

          if (response) {
            setDefaultValue(response);
            setPrimary(response);
          }
        } else {
          const response: IOneCategory[] = await request(
            `${config.API}/categories/?page=${pagination.page}&perPage=1000&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}&restaurant=${restId.restaurantsList}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${token as string}`,
            },
            true
          );

          if (response) {
            setDefaultValue(response);
            setPrimary(response);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },

    Search: async () => {
      try {
        setPrimary([]);
        const search = await request(
          `${config.API}/categories/search?title=${searchValue}&page=${pagination.page}&perPage=1000`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        );

        if (search) {
          setPrimary(search);
        }
      } catch (e) {}
    },
    UpdatePositions: async () => {
      try {
        const data: Array<{ _id: string; position: number }> = [];
        for (let index = 0; index < primary.length; index++) {
          data.push({ _id: primary[index]._id as string, position: index + 1 });
        }
        await request(
          `${config.API}/categories/updatePositions`,
          "PUT",
          { categories: data },
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        );
      } catch (e) {}
    },
  };

  useEffect(() => {
    Callbacks.Fetch();
    setPrimary([]);
  }, [
    pagination.page,
    pagination.perPage,
    filter.sortBy,
    filter.sortOrder,
    searchRest,
    restId,
  ]);

  if (loading || !primary) return <Page.Preloader />;

  return (
    <Page.Wrapper title={Translater.CategoriesPage.title[language.slug]}>
      <Page.Header
        title={Translater.CategoriesPage.title[language.slug]}
        enableButton={access.categories?.change}
        linkTitle={Translater.Buttons.create[language.slug]}
        linkTo="/categories/create"
        saveButton={true}
        saveButtonHandler={Events.saveButtonCliclHandler}
        saveButtonTitle={Translater.Buttons.save[language.slug]}
      />
      <div className="filter-container">
        {/* <ListRestaurants
          selectHandler={Events.selectHandlerRestId}
          restaurantsList={restId.restaurantsList}
        /> */}
        {/* <button
          className="btn btn--default btn--default--green clear-filter"
          onClick={Events.clearFilterHandler}
        >
          {Translater.Items.Clear[language.slug]}
        </button> */}

        <Page.SortBar
          searchBar
          filter={filter}
          value={searchValue}
          perPage={pagination.perPage}
          pressHandler={Events.pressHandler}
          inputHandler={Events.searchHandler}
        />
      </div>

      {primary.length > 0 && (
        <Components.Table
          items={primary}
          setPrimary={setPrimary}
          reloadHandler={Events.reloadHandler}
          sortHandler={Events.sortHandler}
        />
      )}

      <Page.Footer>
        <Page.Pagination
          pagination={pagination}
          setPagination={setPagination}
          paginateHandler={Events.paginateHandler}
          linkTo="/categories"
        />
      </Page.Footer>
    </Page.Wrapper>
  );
};

export default IndexPage;
