import React from "react";
import { HashRouter } from "react-router-dom";

import * as Contexts from "./contexts";
import * as Page from "./components/Page";

const App: React.FC = () => {
  return (
    <HashRouter>
      <Contexts.Collection>
        <Page.Collector />
      </Contexts.Collection>
    </HashRouter>
  );
};

export default App;
