import { TForm } from './types'

export const SInitialForm: any = {
  login: '',
  title: '',
  password: '',
  email: '',
  phone: '',
  facebook: '',
  telegram: '',
  instagram: '',
  headerColor: '#fffs',
}
