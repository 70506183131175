import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"
import * as Buttons from "../../components/Buttons"
import * as Components from "./components"

import { config, Translater } from "../../config"
import { useHttp } from "../../hooks/http.hook"
import { IStaff } from "../../types/items"
import { useHistory } from "react-router-dom"
import { TCreateValidationSchema } from "./types"
import { useValidation } from "../../hooks"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { loading, request } = useHttp()

  const [isClicked, setClicked] = useState(false)

  const [isValid, toggleValid] = useState({
    role: false,
    login: false,
    fullname: false,
    password: false,
  })

  const [form, setForm] = useState<IStaff>({
    role: "",
    login: "",
    fullname: "",
    password: "",
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TCreateValidationSchema>(
    () => ({
      role: {
        condition: form.role.toString().length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.role[language.slug]}`,
      },
      login: {
        condition: !!form.login,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.login[language.slug]}`,
      },
      fullname: {
        condition: form.fullname.toString().length >= 4,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.fullname[language.slug]}`,
      },
      password: {
        //@ts-ignore
        condition: form.password?.length >= 6,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.password[language.slug]}`,
      },
    }),
    [form, Translater, language]
  )

  const { errors, validationSchema, validation } = useValidation(schema)

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    setRole: (role: string) => {
      setForm({ ...form, role })
    },
    createHandler: async () => {
      try {
        setClicked(true)
        await validation()

        Callbacks.Create()
      } catch (e) {
        toogleIsAlertOpen(true)
      }
    },
  }

  const Callbacks = {
    Create: async () => {
      try {
        const response = await request(
          `${config.API}/admins`,
          "POST",
          { ...form },
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response.status === "ok") {
          history.goBack()
        }
      } catch (e) {}
    },
  }

  useEffect(() => {
    if (form.fullname.length >= 3) toggleValid({ ...isValid, fullname: true })
    else toggleValid({ ...isValid, fullname: false })
  }, [form.fullname])

  useEffect(() => {
    if (form.login.length >= 4) toggleValid({ ...isValid, login: true })
    else toggleValid({ ...isValid, login: false })
  }, [form.login])

  useEffect(() => {
    if (form.role) toggleValid({ ...isValid, role: true })
    else toggleValid({ ...isValid, role: false })
  }, [form.role])

  useEffect(() => {
    if ((form.password as string).length >= 6)
      toggleValid({ ...isValid, password: true })
    else toggleValid({ ...isValid, password: false })
  }, [form.password])

  return (
    <Page.Wrapper title={Translater.StaffCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.StaffCreatePage.title[language.slug]}
        backButtonLink="/staff"
      />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />

      <Components.ConfigBlock
        isCreate
        form={form}
        isValid={validationSchema}
        setRole={Events.setRole}
        inputHandler={Events.inputHandler}
        selectHandler={Events.selectHandler}
        isClicked={isClicked}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default CreatePage
