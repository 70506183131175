import React, {
  useState,
  useEffect,
  createContext,
} from "react"

import {
  EVars,
  ISidebarContext,
  TSidebarActions
} from "../types/contexts/sidebar"


const SidebarContext = createContext<ISidebarContext>({
  isActive: false,
  actions: {
    SHOW: () => { },
    HIDE: () => { },
    SYNC: () => { },
    TOGGLE: () => { }
  }
})

export default SidebarContext



export const SidebarContextProvider: React.FC = ({ children }) => {

  const [isActive, toggleActive] = useState<boolean>(false)

  const actions: TSidebarActions = {
    SHOW: () => {
      toggleActive(true)
      localStorage.setItem(EVars.SIDEBAR, 'true')
    },
    HIDE: () => {
      toggleActive(false)
      localStorage.setItem(EVars.SIDEBAR, 'false')
    },
    SYNC: () => {
      const response = JSON.parse((localStorage.getItem(EVars.SIDEBAR) as string))
      if (typeof response === 'boolean') toggleActive(response)
      else toggleActive(true)
    },
    TOGGLE: () => {
      if (isActive) actions.HIDE()
      else actions.SHOW()
    }
  }

  useEffect(() => {
    actions.SYNC()
  }, [])

  return (
    <SidebarContext.Provider value={{
      isActive, actions
    }}>
      {children}
    </SidebarContext.Provider>
  )
}