import React from 'react'

import { ILoginButtonProps } from './types'


const LoginButton: React.FC<ILoginButtonProps> = ({
  title,
  disabled,
  buttonHandler
}) => {

  return (
    <button
      className='btn btn--auth'
      disabled={disabled}
      onClick={buttonHandler}
    >
      {title}
    </button>
  )
}
export default LoginButton