import { ITableTitle } from "../../../../types/table"

export const STitles: ITableTitle[] = [
  // { type: "header", slug: "" },
  { type: "header", slug: "increment" },
  { type: "header", slug: "photo" },
  { type: "header", slug: "name", sort: true },
  { type: "header", slug: "category" },
  { type: "header", slug: "hidden", sort: true },
  { type: "header", slug: "price", sort: true },
  { type: "header", slug: "weight", sort: true },
  { type: "header", slug: "updatedAt", sort: true },
  { type: "header", slug: "createdAt", sort: true },
  { type: "header", slug: "" },
]
