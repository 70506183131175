import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  ChangeEvent,
} from "react";
import * as Contexts from "../../../contexts";
import * as Form from "../../../components/Form";
import * as Page from "../../../components/Page";
import * as Utils from "../../../utils";
import { nanoid } from "nanoid";
import "./config_block.scss";
import deleteImage from "../../../assets/icons/delete.png";
import { config, Translater } from "../../../config";
import { useHttp } from "../../../hooks";

import { SHiddenOptions } from "../../../components/Form/static";

import { IConfigBlockProps } from "./types";
import { DefaultButton } from "../../../components/Buttons";
import {
  IOneAddition,
  IOneCategory,
  IOneProduct,
  IOneRestaurant,
} from "../../../types";

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  data,
  form,
  showPhoto = false,
  setCategory,
  inputHandler,
  selectHandler,
  setForm,
  textareaHandler,
  isDetailPage = false,
  setRestaurant,
  isClicked,
}) => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { request, totalCount } = useHttp();
  const [weight, setWeight] = useState<string>();
  const [price, setPrice] = useState<number>();

  const [showValue, setShowValue] = useState<boolean>(false);
  const [, setInputValue] = useState<string>("");
  const [, setInputRestaurantValue] = useState<string>("");
  const [categoryOptions, setCategoryOptions] = useState<IOneCategory[]>([]);
  const [restaurantOptions, setRestaurantOptions] = useState<IOneRestaurant[]>(
    []
  );
  const [previews, setPreviews] = useState<{ preview: string; id: string }[]>(
    []
  );
  console.log(form);

  const [children, setChildren] = useState<IOneProduct[]>([]);
  const [, setCategorySelectValue] = useState<any>({
    label: "",
    value: "",
  });
  const [, setRestaurantSelectValue] = useState<any>({
    label: "",
    value: "",
  });
  const [adds, setAdds] = useState<IOneAddition[]>([]);
  const [ad, setAd] = useState<any>([]);
  const [rest, setRest] = useState<any>({});
  const [vars, setVars] = useState<{
    nameUA: string;
    nameRU: string;
    priceChange: string;
    weightChange: string;
  }>({ nameUA: "", nameRU: "", priceChange: "", weightChange: "" });
  const [searchSelAds, setSearchSelAds] = useState<string>("");

  const Callbacks = {
    loadAdditionsById: async (
      id = form.restaurant[0] ? form.restaurant[0] : "0"
    ) => {
      try {
        const adds: IOneAddition[] = await request(
          `${config.API}/addition?perPage=100`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
        setAdds(adds);
      } catch (e) {
        console.log(e);
      }
    },
  };

  const Events = {
    inputHandler: (value: string) => {
      setInputValue(value);
    },
    inputRestaurantHandler: (value: string) => {
      setInputRestaurantValue(value);
    },
    loadChildrenOptions: async () => {
      try {
        if (!!rest) {
          const response: IOneProduct[] = await request(
            `${config.API}/products?perPage=1000`,
            "GET",
            null,
            {
              Authorization: `Bearer ${token}`,
            }
          );

          setChildren(response);
        }
      } catch (e) {
        console.log(e);
      }
    },
    loadRestaurantOptions: async () => {
      try {
        const response: IOneRestaurant[] = await request(
          `${config.API}/restaurants`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
        if (response) {
          // setForm &&
          //   setForm({ ...form, restaurant: response.map((item) => item._id) });
        }
        setRestaurantOptions(response);
      } catch (e) {}
    },
    loadCategoriesOptions: async () => {
      try {
        const response: IOneCategory[] = await request(
          `${config.API}/categories?perPage=50`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        setCategoryOptions(response);
      } catch (e) {
        console.log(e);
      }
    },
    optionHandler: (option: any) => {
      setCategorySelectValue(option);
      setCategory(option.value);
    },
    optionRestaurantHandler: (option: any) => {
      setRestaurantSelectValue(option);
      setRestaurant && setRestaurant(option);
    },
    buttonHandler: () => {
      setShowValue(!showValue);
    },

    arrowsHandler: (idx: number, old: number) => {
      const variations = Utils.changeArrayItemPosition(
        idx,
        old,
        form.variations
      );
      setForm && setForm({ ...form, variations });
    },
    selectHandler: (e: any) => {
      console.log("E", e.target.value);
    },
  };

  useEffect(() => {
    Events.loadRestaurantOptions();
  }, []);

  useEffect(() => {
    Events.loadChildrenOptions();
  }, []);

  useEffect(() => {
    Events.loadCategoriesOptions();
  }, []);

  useEffect(() => {
    if (data?.isVariated && data.variations.length) {
      setPrice(+data.variations[0].priceChange);
    }
  }, []);

  useEffect(() => {
    if (
      data?.isVariated &&
      data.variations.length &&
      data.variations[0].weightChange
    ) {
      setWeight(data.variations[0].weightChange);
    }
  }, []);

  useEffect(() => {
    if (form.restaurant) {
      Callbacks.loadAdditionsById();
    } else {
      Callbacks.loadAdditionsById(rest._id);
    }
  }, [rest, form.restaurant]);

  const adsSelectHandler = (ad: any) => {
    setAd(ad);
  };

  const addOptionHandler = () => {
    if (Object.keys(ad).length) {
      const additions = form.additions && [
        ...form.additions.filter((i) => i !== ad._id),
        ad,
      ];

      setForm && setForm({ ...form, additions });
    }
  };

  const deleteAd = (id: string) => {
    if (id) {
      setForm &&
        setForm({
          ...form,
          additions: form.additions?.filter((i) => i._id !== id),
        });
    } else {
      setForm &&
        setForm({
          ...form,
          additions: form.additions?.filter((i) => i != null),
        });
    }
  };

  const deleteVar = (variation: string) => {
    setForm &&
      setForm({
        ...form,
        variations: form.variations.filter(
          (i) => i.name[language.slug === "ua" ? 0 : 1].string !== variation
        ),
      });
  };

  const addVar = () => {
    const newVar = {
      name: [
        { lang: "ua", string: vars.nameUA },
        { lang: "ru", string: vars.nameUA },
      ],
      priceChange: vars.priceChange,
      weightChange: vars.weightChange,
    };
    setForm && setForm({ ...form, variations: [...form.variations, newVar] });
    setVars({ nameUA: "", nameRU: "", priceChange: "", weightChange: "" });
  };

  const varsHandler = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setVars({ ...vars, [name]: value });
  };

  useEffect(() => {
    form.isVariated === "true" &&
      setForm &&
      setForm({ ...form, price: 0, weight: "0" });
  }, [form.isVariated]);

  const getFilePreview = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        resolve(event.target?.result as string);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
      fileReader.readAsDataURL(file);
    });
  };

  const removeNewFile = (id: string) => {
    setForm &&
      setForm({
        ...form,
        newPhoto: form.newPhoto?.filter((item) => item.id !== id),
      });
  };
  const deleteOldPhoto = (path: string) => {
    setForm &&
      setForm({
        ...form,
        photo: form.photo.filter((link) => link !== path),
      });
  };
  useEffect(() => {
    if (form?.newPhoto) {
      Promise.all(
        form.newPhoto.map(async (file) => {
          const newPrew = await getFilePreview(file.file);
          return { preview: newPrew, id: file.id };
        })
      )
        .then((newPreviews) => {
          setPreviews(newPreviews);
        })
        .catch((error) => {
          console.error("Error generating previews:", error);
        });
    } else {
      setPreviews([]);
    }
  }, [form.newPhoto]);

  return (
    <Fragment>
      <Form.LabelField
        fontSize="small"
        label={`${Translater.TableTitles.title[language.slug]} UA`}
      >
        <Form.Input
          name="nameUA"
          placeholder={`${Translater.Placeholders.minTwo[language.slug]} UA`}
          isValid={isClicked ? form.nameUA.length > 0 : true}
          value={form.nameUA}
          inputHandler={inputHandler}
        />
      </Form.LabelField>
      <Form.LabelField
        fontSize="small"
        label={`${Translater.TableTitles.title[language.slug]} RU`}
      >
        <Form.Input
          name="nameRU"
          placeholder={`${Translater.Placeholders.minTwo[language.slug]} RU`}
          isValid={isClicked ? form.nameRU.length > 0 : true}
          value={form.nameRU}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={`${Translater.TableTitles.description[language.slug]} UA`}
      >
        <Form.Textarea
          name="descriptionUA"
          placeholder={`${Translater.Placeholders.minTwo[language.slug]} UA`}
          isValid={isClicked ? form.descriptionUA.length > 0 : true}
          value={form.descriptionUA}
          textareaHandler={textareaHandler}
        />
      </Form.LabelField>
      <Form.LabelField
        fontSize="small"
        label={`${Translater.TableTitles.description[language.slug]} RU`}
      >
        <Form.Textarea
          name="descriptionRU"
          placeholder={`${Translater.Placeholders.minTwo[language.slug]} RU`}
          isValid={isClicked ? form.descriptionUA.length > 0 : true}
          value={form.descriptionRU}
          textareaHandler={textareaHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.hasVariations[language.slug]}
      >
        <Form.Select
          name="isVariated"
          options={SHiddenOptions}
          value={form.isVariated + ""}
          selectHandler={selectHandler}
        />
      </Form.LabelField>

      {form.isVariated !== "true" ? (
        <>
          <Form.LabelField
            fontSize="small"
            label={Translater.TableTitles.weight[language.slug]}
          >
            <Form.Input
              name="weight"
              type="number"
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              isValid={isClicked ? form.weight.length > 0 : true}
              value={form.weight}
              inputHandler={inputHandler}
            />
          </Form.LabelField>

          <Form.LabelField
            fontSize="small"
            label={Translater.TableTitles.price[language.slug]}
          >
            <Form.Input
              type="number"
              name="price"
              value={form.price}
              isValid={isClicked ? form.price.toString().length > 0 : true}
              inputHandler={inputHandler}
            />
          </Form.LabelField>
        </>
      ) : (
        <>
          {form.variations.map((i, idx) => (
            <Page.Item
              deleteField="name"
              idx={idx}
              key={i.name[0].string + idx}
              data={{
                name: i.name[language.slug === "ru" ? 0 : 1].string,
                priceChange: `${i.priceChange}грн`,
                weightChange: i.weightChange ? `${i.weightChange}г` : undefined,
              }}
              deleteHandler={() =>
                deleteVar(i.name[language.slug === "ua" ? 0 : 1].string)
              }
              fields={["name", "priceChange", "weightChange"]}
              arrows
              arrowsHandler={Events.arrowsHandler}
            />
          ))}

          <div>
            <div style={{ marginTop: 20 }}>
              <Form.LabelField
                fontSize="small"
                label={`${Translater.Variants.property[language.slug]} UA`}
              >
                <Form.Input
                  inputHandler={varsHandler}
                  value={vars.nameUA}
                  placeholder={Translater.Placeholders.minTwo[language.slug]}
                  name="nameUA"
                />
              </Form.LabelField>
              <Form.LabelField
                fontSize="small"
                label={`${Translater.Variants.property[language.slug]} RU`}
              >
                <Form.Input
                  inputHandler={varsHandler}
                  value={vars.nameRU}
                  placeholder={Translater.Placeholders.minTwo[language.slug]}
                  name="nameRU"
                />
              </Form.LabelField>

              <Form.LabelField
                fontSize="small"
                label={Translater.TableTitles.weight[language.slug]}
              >
                <Form.Input
                  inputHandler={varsHandler}
                  value={vars.weightChange}
                  type="number"
                  name="weightChange"
                />
              </Form.LabelField>

              <Form.LabelField
                fontSize="small"
                label={Translater.TableTitles.price[language.slug]}
              >
                <Form.Input
                  inputHandler={varsHandler}
                  value={vars.priceChange}
                  type="number"
                  name="priceChange"
                />
              </Form.LabelField>

              <DefaultButton
                title={Translater.Buttons.add[language.slug]}
                buttonHandler={addVar}
              />
            </div>
          </div>
        </>
      )}

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.hidden[language.slug]}
      >
        <Form.Select
          name="hidden"
          options={SHiddenOptions}
          value={form.hidden + ""}
          selectHandler={selectHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.novelty[language.slug]}
      >
        <Form.Select
          name="novelty"
          options={SHiddenOptions}
          value={form.novelty + ""}
          selectHandler={selectHandler}
        />
      </Form.LabelField>

      {!isDetailPage && (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.restaurants[language.slug]}
        >
          <Fragment>
            <Page.SearchSelect
              isMulti
              placeholder={Translater.Placeholders.searchByRests[language.slug]}
              searchField="title"
              data={restaurantOptions}
              setSwitch={Events.optionRestaurantHandler}
              multiField="_id"
              value={isDetailPage ? form.restaurant : null}
              isInvalid={
                isClicked
                  ? !(
                      form.restaurant.length > 1 || form.restaurant.length === 1
                    )
                  : false
              }
            />
          </Fragment>{" "}
        </Form.LabelField>
      )}

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.category[language.slug]}
      >
        <Fragment>
          <select
            className="select__category"
            value={form.category.value}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setForm &&
                setForm({
                  ...form,
                  category: {
                    label:
                      categoryOptions.find(
                        (item) => item._id === e.target.value
                      )?.title[language.slug === "ru" ? 0 : 1].string || "",
                    value: e.target.value,
                  },
                });
            }}
          >
            {!isDetailPage && !form.category.value && (
              <option value="" disabled hidden>
                {Translater.ChooseCategory[language.slug]}
              </option>
            )}
            {categoryOptions.map((item) => (
              <option
                key={item._id}
                value={item._id}
                label={item.title[language.slug === "ru" ? 0 : 1].string}
              ></option>
            ))}
          </select>
        </Fragment>
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.parentProduct[language.slug]}
      >
        <Fragment>
          <>
            <Form.Dropdown
              disabledValue={
                Translater.Placeholders.parentProduct[language.slug]
              }
              isOption={true}
              items={children?.map((item) => ({
                _id: item?._id,
                value: item?._id,
                title: item.name[language.slug === "ua" ? 0 : 1].string,
              }))}
              searchField="name"
              item={form.parent}
              name="parent"
              changeHandler={selectHandler}
            />
          </>
        </Fragment>
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.photo[language.slug]}
      >
        <Form.Input
          type="file"
          name="photo"
          multiple
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.photo[language.slug]}
      >
        <ul className="image__list">
          {previews?.map((item) => (
            <li key={nanoid()} className="image__item">
              <img
                className="image__product"
                alt="Product_image"
                src={item.preview}
              />
              {form.photo.length + (form.newPhoto?.length || 0) > 1 && (
                <button onClick={() => removeNewFile(item.id)}>
                  <img alt="delete" src={deleteImage} />
                </button>
              )}
            </li>
          ))}
          {form.photo.map((item) => (
            <li className="image__item" key={nanoid()}>
              <img
                className="image__product"
                alt="Product_image"
                src={config.imageProxy.concat(item)}
              />
              {form.photo.length + (form.newPhoto?.length || 0) > 1 && (
                <button
                  onClick={() => {
                    deleteOldPhoto(item);
                  }}
                >
                  {<img alt="delete" src={deleteImage} />}
                </button>
              )}
            </li>
          ))}
        </ul>
      </Form.LabelField>

      {form.additions?.map((i, idx) => (
        <Page.Item
          data={i}
          fields={["name", "price", "weight"]}
          idx={idx}
          key={idx}
          deleteHandler={deleteAd}
          deleteField="_id"
        />
      ))}
      {showValue && adds ? (
        <div>
          {adds && (
            <div className="adds">
              <Page.SearchSelect
                placeholder={
                  Translater.Placeholders.searchAddition[language.slug]
                }
                data={adds.map((item) => ({
                  ...item,
                  name: item.name[language.slug === "ru" ? 0 : 1].string,
                }))}
                searchField="name"
                setSwitch={adsSelectHandler}
                value={searchSelAds}
                setValue={setSearchSelAds}
              />
              <DefaultButton
                title={Translater.Buttons.add[language.slug]}
                buttonHandler={addOptionHandler}
              />
            </div>
          )}
        </div>
      ) : null}

      {access.products?.change && (
        <DefaultButton
          title={Translater.Buttons.addOptions[language.slug]}
          buttonHandler={Events.buttonHandler}
        />
      )}
    </Fragment>
  );
};

export default ConfigBlock;
