import { TLanguages } from "../types/contexts/language";

export const Languages: TLanguages = {
  ua: {
    slug: "ua",
    label: "Українська",
  },
  ru: {
    slug: "ru",
    label: "Русский",
  },
};

export const Translater = {
  PaymentType: {
    terminal: {
      ua: "Термінал",
      ru: "Терминал",
    },
    online: {
      ua: "Онлайн",
      ru: "Онлайн",
    },
    cash: {
      ua: "Готівкою",
      ru: "Наличными",
    },
  },
  Call: {
    ua: "Передзвонити",
    ru: "Перезвонить",
  },
  Birthday: {
    ua: "День народження у ",
    ru: "День рождения у ",
  },
  Phone: {
    ua: "Телефон",
    ru: "Телефон",
  },
  Success: {
    save: {
      ua: "Ви успiшно змiнили порядок ресторанiв",
      ru: "Вы успешно изменили порядок ресторанов",
    },
  },
  Erros: {
    oops: {
      ua: "Упс...",
      ru: "Упс...",
    },
    network: {
      ua: "Відсутнє підключення до інтернету",
      ru: "Отсутствует подключение к интернету",
    },
    forbidden: {
      ua: "Ви не немаєте прав доступу!",
      ru: "Нет прав доступа!",
    },
  },
  WS: {
    Actions: {
      STATUS_CHANGE: {
        ua: "",
        ru: "",
      },
    },
    Types: {
      ORDER: {
        ua: "",
        ru: "",
      },
    },
  },
  Sidebar: {
    options: {
      ua: "Опції для продуктів",
      ru: "Опции для продуктов",
    },
    online: {
      ua: "Онлайн",
      ru: "Онлайн",
    },
    home: {
      ua: "Головна",
      ru: "Главная",
    },
    feedback: {
      ua: "Вiдгуки",
      ru: "Отзывы",
    },
    orders: {
      ua: "Замовлення",
      ru: "Заказы",
    },
    categories: {
      ua: "Категорії",
      ru: "Категории",
    },
    restaurants: {
      ua: "Ресторани",
      ru: "Рестораны",
    },
    booking: {
      ua: "Заявки на бронювання",
      ru: "Заявки на бронь",
    },
    bookingDetails: {
      ua: "Бронювання",
      ru: "Бронирование",
    },
    referals: {
      ua: "Реферальні коди",
      ru: "Реферальные коды",
    },
    products: {
      ua: "Продукти",
      ru: "Продукты",
    },
    sales: {
      ua: "Акцiї",
      ru: "Акции",
    },
    users: {
      ua: "Клієнти",
      ru: "Клиенты",
    },
    birthdays: {
      ua: "Дні народження",
      ru: "Дни рождения",
    },
    staff: {
      ua: "Персонал",
      ru: "Персонал",
    },
    roles: {
      ua: "Посади",
      ru: "Должности",
    },
    history: {
      ua: "Архів",
      ru: "Архив",
    },
    notifications: {
      ua: "Повідомлення",
      ru: "Уведомления",
    },
    delivery: {
      ua: "Доставка та оплата",
      ru: "Доставка и оплата",
    },
    stocks: {
      ua: "Акції",
      ru: "Акции",
    },
    settings: {
      ua: "Налаштування",
      ru: "Настройки",
    },
    news: {
      ua: "Новини",
      ru: "Новости",
    },
  },
  Buttons: {
    updateProducts: {
      ua: "Оновити продукти",
      ru: "Обновить продукты",
    },
    push: {
      ua: "Повторно надіслати",
      ru: "Повторно отправить",
    },
    controlPoints: {
      ua: "Доставка в iншi райони",
      ru: "Доставка в другие районы",
    },
    addVariations: {
      ua: "Додати варіації",
      ru: "Добавить вариации",
    },
    login: {
      ua: "Увійти",
      ru: "Войти",
    },
    goBack: {
      ua: "Повернутись назад",
      ru: "Вернуться назад",
    },
    reload: {
      ua: "Перезапустити",
      ru: "Перезапустить",
    },
    logout: {
      ua: "Вийти",
      ru: "Выйти",
    },
    save: {
      ua: "Зберегти",
      ru: "Сохранить",
    },
    delete: {
      ua: "Видалити",
      ru: "Удалить",
    },
    create: {
      ua: "Створити",
      ru: "Создать",
    },
    addOptions: {
      ua: "Додати опції",
      ru: "Добавить опции",
    },
    deleteOptions: {
      ua: "Видалити опції",
      ru: "Удалить опции",
    },
    add: {
      ua: "Додати",
      ru: "Добавить",
    },
    send: {
      ua: "Надіслати",
      ru: "Отправить",
    },
  },
  Alert: {
    save: {
      ua: "Зберегти?",
      ru: "Сохранить?",
    },
    delete: {
      ua: "Видалити?",
      ru: "Удалить?",
    },
  },
  Select: {
    restaurant: {
      ua: "По ресторану",
      ru: "По ресторану",
    },
    orderStatus: {
      ua: "По статусу",
      ru: "По статусу",
    },
    id: {
      ua: "По ID",
      ru: "По ID",
    },
    createdAt: {
      ua: "По датi створення",
      ru: "По дате создания",
    },
    new: {
      ua: "Новий",
      ru: "Новый",
    },
    working: {
      ua: "Готується",
      ru: "Готовится",
    },
    delivering: {
      ua: "Доставляється",
      ru: "Доставляется",
    },
    rejected: {
      ua: "Відмовлений",
      ru: "Отменён",
    },
    done: {
      ua: "Завершений",
      ru: "Завершенный",
    },
    yes: {
      ua: "Так",
      ru: "Да",
    },
    no: {
      ua: "Ні",
      ru: "Нет",
    },
    view: {
      ua: "Перегляд",
      ru: "Просмотр",
    },
    change: {
      ua: "Редагування",
      ru: "Редактирование",
    },
    hide: {
      ua: "Приховати",
      ru: "Скрыть",
    },
    processing: {
      ua: "Обробка",
      ru: "Обработка",
    },
    "payment-error": {
      ua: "Помилка при оплаті",
      ru: "Ошибка при оплате",
    },
    "payment-paid": {
      ua: "Сплачено",
      ru: "Оплачено",
    },
    "pending payment": {
      ua: "Очікування платежу",
      ru: "Ожидание платежа",
    },
    completed: {
      ua: "Завершений",
      ru: "Завершенный",
    },
    canceled: {
      ua: "Скасовано",
      ru: "Отменен",
    },
  },
  Placeholders: {
    enterNumber: {
      ua: "Введіть число",
      ru: "Введите число",
    },
    enterAddress: {
      ua: "Введіть адрес",
      ru: "Введите адрес",
    },
    chooseCategory: {
      ua: "Виберіть категорію",
      ru: "Выберите категорию",
    },
    chooseRestaurant: {
      ua: "Виберіть ресторан",
      ru: "Выберите ресторан",
    },
    chooseRestaurant2: {
      ua: "Вибір ресторану",
      ru: "Выбор ресторана",
    },
    chooseProduct: {
      ua: "Виберіть продукт",
      ru: "Выберите продукт",
    },
    startRange: {
      ua: "Початок кiлометражу",
      ru: "Начало километража",
    },
    endRange: {
      ua: "Кiнець кiлометражу",
      ru: "Конец километража",
    },
    searchAddition: {
      ua: "Пошук по добавкам",
      ru: "Поиск по добавкам",
    },
    parentProduct: {
      ua: "Пошук по бат. продуктам",
      ru: "Поиск по род. продуктам",
    },
    category: {
      ua: "Пошук по категоріям",
      ru: "Поиск по категориям",
    },
    filterByCategory: {
      ua: "Фільтр по категоріям",
      ru: "Фильтр по категориям",
    },
    searchByRests: {
      ua: "Пошук по ресторанам",
      ru: "Поиск по ресторанам",
    },
    g: {
      ua: "Грамми",
      ru: "Граммы",
    },
    options: {
      title: {
        ua: "Назва опції",
        ru: "Имя опции",
      },
      price: {
        ua: "Ціна опції",
        ru: "Цена опции",
      },
      photo: {
        ua: "Фото",
        ru: "Фото",
      },
    },
    colorPicker: {
      ua: "Колiр",
      ru: "Цвет",
    },
    minTwo: {
      ua: "Не менше 2 літер",
      ru: "Не менее 2 букв",
    },
    minThree: {
      ua: "Не менше 3 літер",
      ru: "Не менее 3 букв",
    },
    minFour: {
      ua: "Не менше 4 літер",
      ru: "Не менее 4 букв",
    },
    minSix: {
      ua: "Не менше 6 літер",
      ru: "Не менее 6 букв",
    },
    noOptionsMessage: {
      ua: "Нема збігів :(",
      ru: "Нету совпадений :(",
    },
    loadingMessage: {
      ua: "Завантаження...",
      ru: "Загрузка...",
    },
    searchInput: {
      ua: "Пошук за випадковим збігом",
      ru: "Поиск по совпадению",
    },
    search: {
      ua: "Пошук по замовленням",
      ru: "Поиск по заказам",
    },
    tapsLat: {
      ua: "Введiть широту",
      ru: "Введите широту",
    },
    tapsLng: {
      ua: "Введiть довготу",
      ru: "Введите долготу",
    },
    tapsLinks: {
      ua: "Введiть посилання",
      ru: "Введите ссылку",
    },
  },
  TableTitles: {
    additions: { ua: "Опції для продуктів", ru: "Additions" },
    landings: { ua: "Лендінги", ru: "Landings" },
    settings: { ua: "Налаштування", ru: "Settings" },
    seo: { ua: "SEO", ru: "SEO" },
    ordersHistory: { ua: "Архив", ru: "Archive" },
    banner: { ua: "Баннери", ru: "Banners" },
    hide: {
      ua: "Приховати",
      ru: "Спрятать",
    },
    view: {
      ua: "Переглядати",
      ru: "Просмотр",
    },
    change: {
      ua: "Редагувати",
      ru: "Изменять",
    },
    delete: {
      ua: "Редагувати та видаляти",
      ru: "Удалять",
    },
    infiniteStock: {
      ua: "Безсрочна акція",
      ru: "Бессрочная акция",
    },
    amountOfProducts: {
      ua: "Кілкість продуктів",
      ru: "Количество продуктов",
    },
    paymentBonuses: {
      ua: "З урахуванням бонусів",
      ru: "С учетом бонусов",
    },
    archive: {
      ua: "",
      ru: "",
    },
    position: {
      ua: "№",
      ru: "№",
    },
    hasVariations: {
      ua: "Є варіації",
      ru: "Есть вариации",
    },
    addition: {
      ua: "Додатки",
      ru: "Добавки",
    },
    used: {
      ua: "Викоростовано",
      ru: "Выполненно",
    },
    operation: {
      ua: "Операцiя",
      ru: "Операция",
    },
    g: {
      ua: "Вага",
      ru: "Вес",
    },
    news: {
      ua: "Новини",
      ru: "Новости",
    },
    bonusesToOrders: {
      ua: "Відсоток оплати бонусами при замовленні",
      ru: "Процент оплаты бонусами при заказе",
    },
    cashbackToOrders: {
      ua: "Відсоток кешбеку при замовленні",
      ru: "Процент кешбека при заказе",
    },
    usersSearch: {
      ua: "Пошук по користувачам",
      ru: "Поиск по пользователям",
    },

    noInfo: {
      ua: "Немає інформації",
      ru: "Нету информации",
    },
    priceToDoor: {
      ua: "Оплата доставки",
      ru: "Оплата доставки",
    },
    freeDeliverySum: {
      ua: "Сумма після якої безкоштовна доставка",
      ru: "Сумма после которой бесплатная доставка",
    },
    default: {
      ua: "За замовчуванням",
      ru: "По умолчанию",
      true: {
        ua: "Так",
        ru: "Да",
      },
      false: {
        ua: "Ні",
        ru: "Нет",
      },
    },
    field: {
      ua: "Ім'я",
      ru: "Имя",
    },
    incRest: {
      ua: "По ID замовлення",
      ru: "По ID заказа",
    },
    id: {
      ua: "По ID",
      ru: "По ID",
    },
    createdAt: {
      ua: "По датi створення",
      ru: "По дате создания",
    },
    lastUpdateDate: {
      ua: "Дата змiни",
      ru: "Дата изменения",
    },
    publicationDate: {
      ua: "Дата публікації/Змiни",
      ru: "Дата публикации/Изменения",
    },
    restoraunt: {
      ua: "Ресторан",
      ru: "Ресторан",
    },
    paymentType: {
      ua: "Тип оплати",
      ru: "Тип оплаты",
    },
    promo: {
      ua: "Промокод",
      ru: "Промокод",
    },
    dataStart: {
      ua: "Початок акцiї",
      ru: "Начало акции",
    },
    dataEnd: {
      ua: "Кiнець акциiї",
      ru: "Конец акции",
    },
    freeDelivery: {
      ua: "Безкоштовна доставка",
      ru: "Бесплатная доставка",
    },
    stockDelivery: {
      ua: "Знижка при самовивозi",
      ru: "Скидка при самовывозе",
    },
    label: {
      ua: "Label",
      ru: "Label",
    },
    guests: {
      ua: "Кількість гостей",
      ru: "Количество гостей",
    },
    addressLabel: {
      ua: "Адреса",
      ru: "Адреса",
    },
    startTime: {
      ua: "Час роботи з",
      ru: "Время работы с",
    },
    endTime: {
      ua: "Час роботи по",
      ru: "Время работы по",
    },
    email: {
      ua: "E-mail",
      ru: "E-mail",
    },
    license: {
      ua: "Ліцензія",
      ru: "Лицензия",
    },
    facebook: {
      ua: "Facebook",
      ru: "Facebook",
    },
    instagram: {
      ua: "Instagram",
      ru: "Instagram",
    },
    telegram: {
      ua: "Telegram",
      ru: "Telegram",
    },
    longitude: {
      ua: "Довгота",
      ru: "Долгота",
    },
    latitude: {
      ua: "Широта",
      ru: "Широта",
    },
    restaurant: {
      ua: "Ресторан",
      ru: "Ресторан",
    },
    bonuses: {
      ua: "Бонуси",
      ru: "Бонусы",
    },
    date: {
      ua: "Дата",
      ru: "Дата",
    },
    number: {
      ua: "№",
      ru: "№",
    },
    slug: {
      ua: "Заголовок",
      ru: "Заголовок",
    },
    bonusesPercents: {
      ua: "Сума оплати бонусами в %",

      ru: "Сумма оплаты бонусами в %",
    },
    timeEnd: {
      ua: "Залишилось часу",
      ru: "Осталось времени",
    },
    rate: {
      ua: "Оцiнка",
      ru: "Оценка",
    },
    product: {
      ua: "Продукт",
      ru: "Продукт",
    },
    stocks: {
      ua: "Акції",
      ru: "Акции",
    },
    stocks_main_view: {
      ua: "Перегляд",
      ru: "Просмотр",
    },
    stocks_main_change: {
      ua: "Редагування",
      ru: "Редактирование",
    },
    feedback: {
      ua: "Вiдгуки",
      ru: "Отзывы",
    },
    referals: {
      ua: "Реферальні коди",
      ru: "Реферальные коды",
    },
    booking: {
      ua: "Заявки на бронювання",
      ru: "Заявки на бронь",
    },
    null: {
      ua: "Немає",
      ru: "Нет",
    },
    city: {
      ua: "Місто",
      ru: "Город",
    },
    percent: {
      ua: "%",
      ru: "%",
    },
    percentSizeError: {
      ua: "Значення повино бути від 1 до 99",
      ru: "Значение должно быть от 1 до 99",
    },
    endStock: {
      ua: "Акція закінчилась",
      ru: "Акция закончилась",
    },
    expiresDate: {
      ua: "Закiнчено",
      ru: "Завершено",
    },
    admin: {
      ua: "Адміністратори",
      ru: "Администраторы",
    },
    message: {
      ua: "Повідомлення",
      ru: "Сообщение",
    },
    processing: {
      ua: "Обробка",
      ru: "Обработка",
    },
    staff: {
      ua: "Персонал",
      ru: "Персонал",
    },
    roles: {
      ua: "Посади",
      ru: "Должности",
    },
    users: {
      ua: "Користувачі",
      ru: "Пользователи",
    },
    dateOfBirth: {
      ua: "Дата народження",
      ru: "Дата рождения",
    },
    orders: {
      ua: "Замовлення",
      ru: "Заказы",
    },
    history: {
      ua: "Архів замовлень",
      ru: "Архив заказов",
    },
    deleting: {
      ua: "Bидалення",
      ru: "Удаление",
    },
    categories: {
      ua: "Категорiї",
      ru: "Категории",
    },
    similar: {
      ua: "Cхоже",
      ru: "Похожее",
    },
    password: {
      ua: "Пароль",
      ru: "Пароль",
    },
    deliveringPrice: {
      ua: "Ціна доставки",
      ru: "Цена доставки",
    },
    archived: {
      ua: "Доданий в архів",
      ru: "Добавлен в архив",
    },
    favourites: {
      ua: "Вибране",
      ru: "Избранное",
    },
    addToSimilar: {
      ua: "Додати до схожого",
      ru: "Добавить к похожему",
    },
    averageStars: {
      ua: "Середня оцінка",
      ru: "Средняя оценка",
    },
    novelty: {
      ua: "Новинка",
      ru: "Новинка",
      true: {
        ua: "Так",
        ru: "Да",
      },
      false: {
        ua: "Ні",
        ru: "Нет",
      },
    },
    promotionalOffer: {
      ua: "Акція 2+1",
      ru: "Акция 2+1",
      true: {
        ua: "Так",
        ru: "Да",
      },
      false: {
        ua: "Ні",
        ru: "Нет",
      },
    },
    novetly: {
      ua: "Новинка",
      ru: "Новинка",
      true: {
        ua: "Так",
        ru: "Да",
      },
      false: {
        ua: "Ні",
        ru: "Нет",
      },
    },

    starsCount: {
      ua: "Kількість відгуків",
      ru: "Kоличество отзывов",
    },
    stock: {
      ua: "В наявності",
      ru: "В наличии",
    },
    category: {
      ua: "Kатегорія",
      ru: "Kатегория",
    },
    price: {
      ua: "Ціна",
      ru: "Цена",
    },
    products: {
      ua: "Продукти",
      ru: "Продукты",
    },
    restaurants: {
      ua: "Ресторани",
      ru: "Рестораны",
    },
    restaurantsList: {
      ua: "Список ресторанів",
      ru: "Список ресторанов",
    },

    productsCount: {
      ua: "Кількість продуктів",
      ru: "Kоличество продуктов",
    },
    subCategories: {
      ua: "Підкатегорії",
      ru: "Подкатегории",
    },
    subCategoriesCount: {
      ua: "Кількість підкатегорій",
      ru: "Kоличество подкатегорий",
    },
    parentCategory: {
      ua: "Батьківська категорія",
      ru: "Родительская категория",
    },
    parentProduct: {
      ua: "Батьківський продукт",
      ru: "Родительский продукт",
    },
    increment: {
      ua: "№",
      ru: "№",
    },
    fullname: {
      ua: "ПІБ",
      ru: "ФИО",
    },
    nutritionalValue: {
      ua: "Харчова цінність",
      ru: "Пищевая ценность",
    },
    hidden: {
      ua: "Прихована",
      ru: "Cкрытая",
      true: {
        ua: "Так",
        ru: "Да",
      },
      false: {
        ua: "Ні",
        ru: "Нет",
      },
    },
    login: {
      ua: "Логін",
      ru: "Логин",
    },
    role: {
      ua: "Посада",
      ru: "Должность",
    },
    sum: {
      ua: "Сума",
      ru: "Суммa",
    },
    code: {
      ua: "Реферальний код",
      ru: "Реферальный код",
    },
    itemsPrice: {
      ua: "Сума",
      ru: "Суммa",
    },
    user: {
      ua: "Клієнти",
      ru: "Клиенты",
    },
    orderStatus: {
      ua: "Статус",
      ru: "Статус",
    },
    comment: {
      ua: "Коментар",
      ru: "Комментарий",
    },
    delivery: {
      ua: "Доставка",
      ru: "Доставка",
    },
    deliveryPrice: {
      ua: "Ціна за доставку",
      ru: "Цена за доставку",
    },
    commonPrice: {
      ua: "Загальна вартість",
      ru: "Общая стоимость",
    },
    deliveryTime: {
      ua: "Час доставки",
      ru: "Время доставки",
    },
    recipient: {
      ua: "Отримувач",
      ru: "Получатель",
    },
    call: {
      ua: "Подзвонити",
      ru: "Звонить",
    },
    phone: {
      ua: "Телефон",
      ru: "Телефон",
    },
    photo: {
      ua: "Фото",
      ru: "Фото",
    },
    headerPhoto: {
      ua: "Фото в шапку додатка",
      ru: "Фото в шапку приложения",
    },
    dateStart: {
      ua: "Початок акції",
      ru: "Начало акции",
    },
    dateEnd: {
      ua: "Кінець акції",
      ru: "Конец акции",
    },
    productsList: {
      ua: "Список товарів",
      ru: "Список товаров",
    },
    categoriesList: {
      ua: "Список категорій",
      ru: "Список категорий",
    },

    name: {
      ua: "Назва",
      ru: "Название",
    },
    orderNumber: {
      ua: "Номер замовлення",
      ru: "Hомер заказа",
    },
    pickup: {
      ua: "Самовивіз",
      ru: "Самовывоз",
    },
    subcategory: {
      ua: "Підкатегорія",
      ru: "Подкатегория",
    },
    options: {
      ua: "Опції",
      ru: "Опции",
    },
    options_addition: {
      ua: "Додатково(Вага/шт)",
      ru: "Дополнительно(Вес/шт)",
    },
    options_name: {
      ua: "Назва опції",
      ru: "Название опции",
    },
    options_price: {
      ua: "Ціна опції",
      ru: "Цена опции",
    },
    title: {
      ua: "Назва",
      ru: "Название",
    },
    calories: {
      ua: "Калорійність",
      ru: "Калорийность",
    },
    fats: {
      ua: "Жири",
      ru: "Жиры",
    },
    carbohydrates: {
      ua: "Вуглеводи",
      ru: "Углеводы",
    },
    proteins: {
      ua: "Білки",
      ru: "Белки",
    },
    weight: {
      ua: "Вага",
      ru: "Вес",
    },
    description: {
      ua: "Опис",
      ru: "Описание",
    },
    amount: {
      ua: "Кількість",
      ru: "Количество",
    },
    courier: {
      ua: "Кур'єр",
      ru: "Курьер",
    },
    withoutComment: {
      ua: "[Без комментарів]",
      ru: "[Без комментариев]",
    },
    type: {
      ua: "Тип доставки",
      ru: "Тип доставки",
    },
    address: {
      ua: "Адрес",
      ru: "Адрес",
    },
    statusTypes: {
      "payment-paidByCash": {
        ua: "Оплата готiвкою",
        ru: "Оплата наличными",
      },
      unpaid: {
        ua: "Неоплачений",
        ru: "Неоплаченный",
      },
      new: {
        ua: "Новий",
        ru: "Новый",
      },
      working: {
        ua: "Готується",
        ru: "Готовится",
      },
      delivering: {
        ua: "Доставляється",
        ru: "Доставляется",
      },
      processing: {
        ua: "Обробка",
        ru: "Обработка",
      },
      "pending payment": {
        ua: "Очікування платежу",
        ru: "Ожидание платежа",
      },
      completed: {
        ua: "Завершений",
        ru: "Завершенный",
      },
      canceled: {
        ua: "Скасовано",
        ru: "Отмененный",
      },
      rejected: {
        ua: "Відмовленно",
        ru: "Отменён",
      },
      done: {
        ua: "Завершений",
        ru: "Завершенный",
      },
      "payment-processing": {
        ua: "В обробці",
        ru: "В обработке",
      },
      "payment-error": {
        ua: "Помилка при оплаті",
        ru: "Ошибка при оплате",
      },
      failure: {
        ua: "Помилка при оплаті",
        ru: "Ошибка при оплате",
      },
      "payment-paid": {
        ua: "Сплачено",
        ru: "Оплачено",
      },
      paid: {
        ua: "Сплачено",
        ru: "Оплачено",
      },
      newByCash: {
        ua: "новий(готівка)",
        ru: "новый(наличные)",
      },
      newByOnline: {
        ua: "новий(онлайн)",
        ru: "новый(наличные)",
      },
      "payment-paidByOnline": {
        ua: "Сплата онлайн",
        ru: "Оплата онлайн",
      },
    },
    updatedAt: {
      ua: "Оновлений",
      ru: "Обновлён",
    },
    changedOrderStatus: {
      ua: "Статус замовлення успішно змінено",
      ru: "Статус заказа успешно изменен",
    },
  },
  AuthPage: {
    title: {
      ua: "Авторизація",
      ru: "Авторизация",
    },
    loginPlaceholder: {
      ua: "Логін",
      ru: "Логин",
    },
    passwordPlaceholder: {
      ua: "Пароль",
      ru: "Пароль",
    },
  },
  HomePage: {
    title: {
      ua: "Головна",
      ru: "Главная",
    },
  },
  OrdersPage: {
    title: {
      ua: "Замовлення",
      ru: "Заказы",
    },
  },
  OrdersDetailPage: {
    title: {
      ua: "Замовлення",
      ru: "Заказ",
    },
  },
  CategoriesPage: {
    title: {
      ua: "Категорії",
      ru: "Категории",
    },
    pickRestaurant: {
      ua: "Виберіть ресторан",
      ru: "Выберите ресторан",
    },
  },
  TotalCompletedByStatus: {
    ua: "Усього завершених за статусами:",
    ru: "Всего завершенных по статусам:",
  },
  TotalCompletedByReferals: {
    ua: "Використано реферальних кодів:",
    ru: "Использовано реферальных кодов:",
  },
  TotalCompleted: {
    ua: "Усього заказів:",
    ru: "Всего заказов:",
  },
  TotalReferals: {
    ua: "Усього реферальних кодів:",
    ru: "Всего реферальных кодов: ",
  },
  TotalUsers: {
    ua: "Усього користувачів:",
    ru: "Всего пользователей:",
  },
  UsedReferals: {
    ua: "Використані реферальні коди:",
    ru: "Использованные реферальные коды:",
  },
  WorkingOrders: {
    ua: "Робочі замовлення:",
    ru: "Рабочие заказы:",
  },
  DeliveringOrders: {
    ua: "Замовлення що доставляються:",
    ru: "Доставляемые заказы:",
  },
  DoneOrders: {
    ua: "Виконано замовлень:",
    ru: "Выполнено заказов:",
  },
  DeliveryPage: {
    title: {
      ua: "Доставка та оплата",
      ru: "Доставка и оплата",
    },
  },
  NewByCashOrders: {
    ua: "Нових за касовими ордерами:",
    ru: "Новых по кассовым ордерам:",
  },
  NewUsers: {
    ua: "Нових користувачів:",
    ru: "Новых пользователей: ",
  },
  PaymentErrorOrders: {
    ua: "Замовлення з помилкою оплати:",
    ru: "Заказы с ошибкой оплаты: ",
  },
  RejectedOrders: {
    ua: "Відхилені замовлення:",
    ru: "Отклоненные заказы:",
  },
  PaymentPaidOrders: {
    ua: "Сплачені замовлення:",
    ru: "Оплаченные заказы:",
  },
  CategoriesCreatePage: {
    title: {
      ua: "Створити категорію",
      ru: "Cоздать категорию",
    },
  },
  NotificationsPage: {
    title: {
      ua: "Сторінка повідомлень",
      ru: "Страница уведомлений",
    },
    new_message: {
      ua: "Нове замовлення",
      ru: "Новый заказ",
    },
    save_message: {
      ua: "Сохранено",
      ru: "Збережено",
    },
  },
  BookingPage: {
    title: {
      ua: "Бронювання столиків",
      ru: "Бронирование столиков",
    },
  },
  ReferalsPage: {
    title: {
      ua: "Сторінка реферальних кодів",
      ru: "Страница реферальных кодов",
    },
  },
  StocksCreatePage: {
    title: {
      ua: "Створити акцію",
      ru: "Cоздать акцию",
    },
  },
  StocksDetailPage: {
    title: {
      ua: "Картка акції",
      ru: "Kарточка акции",
    },
  },
  CategoriesDetailPage: {
    title: {
      ua: "Картка категорії",
      ru: "Kарточка категории",
    },
  },
  BookingDetailPage: {
    title: {
      ua: "Редагувати столик",
      ru: "Редактировать столик",
    },
  },
  ReferalsCreatePage: {
    title: {
      ua: "Cтворення реферального коду",
      ru: "Создание реферального кода",
    },
  },
  ReferalsDetailPage: {
    title: {
      ua: "Редагування",
      ru: "Редактирование",
    },
  },
  RestaurantsPage: {
    title: {
      ua: "Ресторани",
      ru: "Рестораны",
    },
  },
  ProductsCreatePage: {
    title: {
      ua: "Створити продукт",
      ru: "Создать продукт",
    },
  },
  ProductsDetailPage: {
    title: {
      ua: "Продукт",
      ru: "Продукт",
    },
  },
  RestaurantsCreatePage: {
    title: {
      ua: "Створити ресторан",
      ru: "Создать ресторан",
    },
  },
  BookingCreatePage: {
    title: {
      ua: "Створити бронь столика",
      ru: "Создать бронь столика",
    },
  },
  RestaurantsDetailPage: {
    title: {
      ua: "Ресторан",
      ru: "Ресторан",
    },
  },
  ProductsPage: {
    title: {
      ua: "Продукти",
      ru: "Продукты",
    },
  },
  StocksPage: {
    title: {
      ua: "Акції",
      ru: "Акции",
    },
  },
  SalesPage: {
    title: {
      ua: "Акцiї",
      ru: "Акции",
    },
  },
  SalesCreatePage: {
    title: {
      ua: "Створити акцiю",
      ru: "Создать акцию",
    },
  },
  SalesDetailPage: {
    title: {
      ua: "Акцiя",
      ru: "Акция",
    },
  },
  UsersPage: {
    title: {
      ua: "Клієнти",
      ru: "Клиенты",
    },
  },
  UsersPageBirthday: {
    title: {
      ua: "День народження",
      ru: "День рождения",
    },
  },
  UsersPageBirthdayChange: {
    title: {
      ua: "Змiнити дату народження",
      ru: "Изменить дату рождения",
    },
  },
  UsersDetailPage: {
    title: {
      ua: "Клієнти",
      ru: "Клиенты",
    },
  },
  StaffPage: {
    title: {
      ua: "Персонал",
      ru: "Персонал",
    },
  },
  StaffCreatePage: {
    title: {
      ua: "Створити співробітника",
      ru: "Создать сотрудника",
    },
  },
  StaffDetailPage: {
    title: {
      ua: "Співробітник",
      ru: "Сотрудник",
    },
  },
  RolesPage: {
    title: {
      ua: "Посади",
      ru: "Должности",
    },
  },
  RoleCreatePage: {
    title: {
      ua: "Створити посаду",
      ru: "Создать должность",
    },
  },
  RoleDetailPage: {
    title: {
      ua: "Посада",
      ru: "Должность",
    },
  },
  ArchivePage: {
    title: {
      ua: "Архів замовлень",
      ru: "Архив заказов",
    },
  },
  FeedbackPage: {
    title: {
      ua: "Вiдгуки",
      ru: "Отзывы",
    },
  },
  SettingsPage: {
    alert: {
      ua: "Товари успішно оновилися",
      ru: "Товары успешно обновились",
    },
    title: {
      ua: "Налаштування",
      ru: "Настройки",
    },
    langSwitchLabel: {
      ua: "Змінити мову :",
      ru: "Сменить язык :",
    },
  },
  ErrorLength: {
    ua: "Введіть коректну довжину поля",
    ru: "Введите корректную длину поля",
  },
  ErrorProcent: {
    ua: "Не менше 0 і не більше 100",
    ru: "Не меньше 0 и не больше 100",
  },
  ErrorRestoran: {
    ua: "Виберіть ресторан",
    ru: "Выбирете ресторан",
  },
  ErrorLinks: {
    ua: "Введіть коректне посилання в полi",
    ru: "Введите корректную ссылку",
  },
  ErrorAddress: {
    ua: "Введіть коректну адресу",
    ru: "Введите корректную адресу",
  },
  ErrorTimeEnd: {
    ua: '"Час по" повинен бути більшим ніж "Час з"',
    ru: '"Время по" должно быть больше "Время с"',
  },
  ErrorTimeStart: {
    ua: '"Час з" повинен бути меншим ніж "Час по"',
    ru: '"Время с" должно быть меньшим "Время по"',
  },
  ErrorLabelRestaurants: {
    ua: "Введіть коректне значення",
    ru: "Введите корректное значение",
  },
  ErrorRestaurants: {
    ua: "Ресторани",
    ru: "Рестораны",
  },
  ErrorTypeNumber: {
    ua: "Введiть числове значення",
    ru: "Введите числовое значение",
  },
  MinMax: {
    ua: "Довжина повина бути вiд 3 до 300 символiв",
    ru: "Длина должна быть от 3 до 300 символов",
  },
  ErrorPhoto: {
    ua: "Необхідно додати фото",
    ru: "Необходимо добавить фото",
  },
  ErrorField: {
    ua: "Оберiть значення поля",
    ru: "Выберите значение поля",
  },
  ErrorVariations: {
    ua: "Потрібно додати хоча б одну варіацію",
    ru: "Нужно добавить хотя бы одну вариацию",
  },

  ErrorSize: {
    ua: "Значення повино бути більше 0",
    ru: "Значение должно быть больше 0",
  },
  SaveButton: {
    ua: "Зберегти",
    ru: "Сохранить",
  },
  ChangePosition: {
    ua: "Змiнити послiдовнiсть ресторанiв",
    ru: "Изменить последовательность ресторанов",
  },
  NewsPage: {
    title: {
      ua: "Новини",
      ru: "Новости",
    },
  },
  OptionsForProductsPage: {
    title: {
      ua: "Опції для продуктів",
      ru: "Опции для продуктов",
    },
    no: {
      ua: "Немає опцій",
      ru: "Нету опций",
    },
    CreatePage: {
      ua: "Створити опцію",
      ru: "Создать опцию",
    },
    DetailPage: {
      ua: "Детальна сторінка",
      ru: "Детальная страница",
    },
    error: {
      length: {
        ua: "Введіть корректну довжину поля: ",
        ru: "Выберите корректную длину поля: ",
      },
      name: {
        ua: "Назва опції",
        ru: "Название опции",
      },
      photo: {
        ua: "Необхідно додати фото",
        ru: "Нужно добавить фото",
      },
      price: {
        ua: "Ціна ",
        ru: "Цена",
      },
    },
  },
  NewsCreatePage: {
    title: {
      ua: "Створити новину",
      ru: "Создать новость",
    },
  },
  NewsDetailPage: {
    title: {
      ua: "Картка новини",
      ru: "Карточка новости",
    },
  },
  RestarauntNotDefined: {
    ua: "Ресторан не вiдомий",
    ru: "Ресторан не известен",
  },
  RadioButtons: {
    user: {
      ua: "Клієнти",
      ru: "Клиенты",
    },
    restoraunt: {
      ua: "Ресторан",
      ru: "Ресторан",
    },
    paymentType: {
      ua: "Тип оплати",
      ru: "Тип оплаты",
    },
  },
  Items: {
    filterByDate: {
      ua: "Часовий проміжок для експорта в Excel:",
      ru: "Часовой промежуток для экспорта в Excel:",
    },
    NoLength: {
      ua: "Немає замовлень",
      ru: "Нету заказов",
    },
    Clear: {
      ua: "Очистити фiльтри",
      ru: "Очистить фильтры",
    },
  },
  Variants: {
    property: {
      ua: "Параметр",
      ru: "Параметр",
    },
  },
  ArrayErrorLength: {
    ua: "Немає нi одного елемента в",
    ru: "Нету ни одного элемента в",
  },
  PercentsCashback: {
    ua: "Відсоток кешбеку",
    ru: "процент кешбека",
  },
  Delivery: {
    ua: "Відключити доставку",
    ru: "Отключить доставку",
  },
  TimeDelivery: {
    ua: "Найближчим часом",
    ru: "В ближайшее время",
  },
  House: { ua: "будинок", ru: "квартира" },
  ChooseCategory: { ua: "Оберіть категорію", ru: "Выберите категорию" },
  TurnOffOnlinePayment: {
    ua: "Відключити онлайн оплату",
    ru: "Отключить онлайн оплату",
  },
  minimumOrder: {
    ua: "Сума мінімального замовлення",
    ru: "Сумма минимального заказа",
  },
  DeliveryDescription: {
    ua: "Вкажіть опис доставки",
    ru: "Укажите описание доставки",
  },
};
