import React from 'react'

import { IDefaultButtonProps } from './types'


const DefaultButton: React.FC<IDefaultButtonProps> = ({
  title,
  disabled = false,
  backgroundColor = 'green',
  buttonHandler,
  right,
  width, 
  marginBottom,
}) => {

  return (
    <button
      className={`btn btn--default btn--default--${backgroundColor} ${right ? 'right' : ''}`}
      disabled={disabled}
      style={{ width, marginBottom }}
      onClick={buttonHandler}
    >
      {title}
    </button>
  )
}

export default DefaultButton