import { ITableTitle } from "../../../../types/table"

export const STitles: ITableTitle[] = [
  { type: "header", slug: "increment" },
  { type: "header", slug: "fullname" },
  { type: "header", slug: "login" },
  { type: "header", slug: "role" },
  { type: "header", slug: "updatedAt" },
  { type: "header", slug: "createdAt" },
  { type: "header", slug: "" },
]
