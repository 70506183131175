import React, { useContext } from "react";
import TConfigBlockProps from "./types";
import * as Components from "./";
import * as CSV from "../../../components/ExcelUpload";
import * as Page from "../../../components/Page";
import { Translater } from "../../../config";
import * as Contexts from "../../../contexts";

const ConfigBlock: React.FC<TConfigBlockProps> = ({
    primary,
    xslsData,
    Events,
}) => {
    const { language } = useContext(Contexts.LanguageContext)
    return (
        <>
            <CSV.ExcelUpload csvData={xslsData} fileName={'orders'} />
            <Page.ClearButton
                onHandler={Events.OnClear}
            >
                {Translater.Items.Clear[language.slug]}
            </Page.ClearButton>
            <Components.Table
                items={primary}
                sortHandler={Events.sortHandler}
                reloadHandler={Events.reloadHandler}
            />
        </>
    );
};

export default ConfigBlock;