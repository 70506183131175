import React, {
  useState,
  useContext,
  ChangeEvent,
  KeyboardEvent
} from 'react'

import * as Contexts from '../../../contexts'
import * as Form from '../../../components/Form'
import * as Buttons from '../../../components/Buttons'

import { useHttp } from '../../../hooks/http.hook'
import {
  config,
  Translater
} from '../../../config'

import { IValidForm, IForm } from './types'


const LoginCard: React.FC = () => {

  const { actions: authActions } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, request } = useHttp()

  const [isValid, toggleValid] = useState<IValidForm>({
    login: true,
    password: true
  })

  const [form, setForm] = useState<IForm>({
    login: '',
    password: ''
  })

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    pressHandler: (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        const button = document.querySelector('.btn--auth')
        button?.classList.add('active')
        setTimeout(() => {
          button?.classList.remove('active')
        }, 200)

        Callbacks.Login()
      }
    },
    loginHandler: () => {
      Callbacks.Login()
    }
  }

  const Callbacks = {
    Login: async () => {
      try {
        const response: any = await request(`${config.API}/login`, 'POST', { ...form })

        if (response && response.token) {
          setForm({ ...form, login: '', password: '' })
          authActions.LOGIN(response.token, response.admin._id)
        } else toggleValid({ ...isValid, login: false, password: false })

      } catch (e) {
        console.log(e)
      }
    }
  }


  return (
    <div className='auth-card'>
      <div className='auth-card-container'>

        <Form.Input
          name='login'
          placeholder={Translater.AuthPage.loginPlaceholder[language.slug]}
          isValid={isValid.login}
          value={form.login}
          inputHandler={Events.inputHandler}
          pressHandler={Events.pressHandler}
        />

        <Form.Input
          name='password'
          type='password'
          placeholder={Translater.AuthPage.passwordPlaceholder[language.slug]}
          isValid={isValid.password}
          value={form.password}
          inputHandler={Events.inputHandler}
          pressHandler={Events.pressHandler}
        />

        <Form.LanguageToggler />

      </div>

      <Buttons.LoginButton
        title={Translater.Buttons.login[language.slug]}
        disabled={loading}
        buttonHandler={Events.loginHandler}
      />
    </div>
  )
}

export default LoginCard